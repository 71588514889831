import * as React from "react";
import styled from "styled-components";
import { IconsId } from "../../assets/fonts/iconfont/icons";
import { colors } from "../../utilities/variables";
import { Icon } from "./Icon";

type Props = {
  icon?: IconsId;
  size?: string;
};

const Wrapper = styled.div`
  width: 180px;
  height: 180px;
  background-color: ${colors.lightestGreen};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OutterCircle = styled.div`
  width: 147px;
  height: 147px;
  background-color: ${colors.lightGreen};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerCircle = styled.div`
  width: 106px;
  height: 106px;
  background-color: ${colors.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarIcon: React.ComponentType<Props> = ({
  icon = "check",
  size = "text-7xl",
}) => {
  return (
    <Wrapper>
      <OutterCircle>
        <InnerCircle>
          <Icon icon={icon} color={"text-white"} size={size} />
        </InnerCircle>
      </OutterCircle>
    </Wrapper>
  );
};
