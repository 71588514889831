import React from "react";
import { Message } from "../../types/Message";
import { Thread } from "../../types/Thread";
import styled from "styled-components";
import { InputField } from "../atoms/InputField";
import { colors } from "../../utilities/variables";
import { Icon } from "../atoms/Icon";
import { Answer } from "../atoms/Answer";
import { useSelector } from "../../store/hooks";
import { useForm } from "react-hook-form";
import { ApolloError, FetchResult } from "@apollo/client";
import { client } from "../../utilities/Apollo";
import CREATE_MESSAGE from "../../query/Message/createMessage";
import { Post } from "../../types/Post";
import CREATE_THREAD from "../../query/Message/createThread";
import { CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

type Props = {
  thread?: Thread | null;
  messages: Message[];
  post: Post | null;
  onThreadCreate?: (thread: Thread) => void;
};

interface IFormInput {
  message: string;
}

const ChatScreen = styled.div`
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
  padding: 1rem;
`;

const SendIcon = styled.div`
  width: 46px;
  height: 46px;
  background-color: ${colors.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 6px solid ${colors.lightGreen};

  cursor: pointer;
`;

const CircularWrapper = styled.div`
  margin-left: 20px;
`;

export const ChatForm: React.ComponentType<Props> = ({
  thread,
  messages,
  post,
  onThreadCreate,
}) => {
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [chatDisabled, setChatDisabled] = React.useState(false);
  const [currentThread, setCurrentThread] = React.useState<
    Thread | null | undefined
  >(thread);

  const AlwaysScrollToBottom = () => {
    const elementRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => elementRef?.current?.scrollIntoView());
    return <div ref={elementRef} />;
  };

  const { register, setValue, getValues, reset } = useForm<IFormInput>({});
  const [message, setMessage] = React.useState<string>("");

  const createNewAnswer = async () => {
    const value = getValues();
    if (!currentThread && value.message !== "") {
      await client
        .mutate({
          mutation: CREATE_THREAD,
          variables: {
            postId: post?.id,
          },
        })
        .then(async (response) => await onThreadCreateSuccess(response))
        .catch((error) => console.log(error));
      return;
    }
    await createAnswer(currentThread?.id as number);
  };

  const createAnswer = async (threadId: number) => {
    const value = getValues();
    if (value.message !== "") {
      setLoading(true);

      await client
        .mutate({
          mutation: CREATE_MESSAGE,
          variables: {
            threadId: threadId,
            message: value.message,
          },
        })
        .then((response) => onMessageSuccess(response))
        .catch((error) => onMessageError(error));
    }
  };

  const onThreadCreateSuccess = async (response: FetchResult) => {
    setCurrentThread(response?.data?.createThread);
    if (onThreadCreate) {
      onThreadCreate(response?.data?.createThread);
    }

    await createAnswer(response?.data?.createThread.id);
  };

  const onMessageSuccess = (response: FetchResult) => {
    reset();
    setMessage("");
    setLoading(false);
  };

  const onMessageError = (error: ApolloError) => {
    setLoading(false);
  };

  React.useEffect(() => {
    register("message", { required: true, value: "" });
    if (thread) {
      setCurrentThread(thread);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  React.useEffect(() => {
    if (
      user.id &&
      user.activeMembership.type === "free" &&
      messages.length === 0
    ) {
      setChatDisabled(true);
    }

    if (
      user.id &&
      user.activeMembership.type === "free" &&
      messages.length > 0
    ) {
      setChatDisabled(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, messages]);

  return (
    <div>
      <div className={"font-medium py-5 px-5 border-b"}>
        {thread?.customer.company || post?.customer.company}
      </div>
      <ChatScreen>
        {messages.map((message) => {
          return <Answer key={message.id} message={message} user={user} />;
        })}
        {chatDisabled && (
          <Alert severity={"error"} className={"my-3"}>
            Du skal have et Premium medlemskab for chat
          </Alert>
        )}
        <AlwaysScrollToBottom />
      </ChatScreen>
      <div className="flex px-6 border-t py-4 items-center">
        <div className="mr-5 w-full">
          <InputField
            disabled={chatDisabled}
            label={"Besked"}
            noErrorString=""
            name={"message"}
            value={message}
            onChange={async (e: any, data: any) => {
              setMessage(e.target.value);
              setValue(e.target.name, e.target.value);
            }}
            radius={100}
          />
        </div>

        {loading && (
          <CircularWrapper className={"text-primary-green mt-2"}>
            <CircularProgress color="inherit" />
          </CircularWrapper>
        )}
        {!loading && (
          <SendIcon onClick={createNewAnswer}>
            <Icon icon={"arrow-up"} color={"text-white"} size={"text-3xl"} />
          </SendIcon>
        )}
      </div>
    </div>
  );
};
