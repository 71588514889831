import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { colors } from "../../utilities/variables";

type Props = {
  title: string;
  link: string;
};

const StyledLink = styled((props) => <Link {...props} />)`
  color: ${(props) => (props.$selected ? colors.white : colors.black)};
  background-color: ${(props) =>
    props.$selected ? colors.black : "transparent"};
  justify-content: center;
  padding: 8px 0;
  border-radius: 60px;
  border: 1px solid
    ${(props) => (props.$selected ? colors.black : colors.lightGrey)};
  width: 180px;
  @media (max-width: 480px) {
    width: 100%;
  }
  display: flex;
`;

export const CustomerDetailLinkButton: React.ComponentType<Props> = ({
  title,
  link,
}) => {
  const location = useLocation();
  return (
    <StyledLink
      className={"font-medium"}
      to={link}
      $selected={location.pathname === link}
    >
      {title}
    </StyledLink>
  );
};
