import { gql } from "@apollo/client";

const GET_MESSAGES = gql`
  query getMessages($threadId: Int!) {
    getMessages(threadId: $threadId) {
      id
      message
      createdAt
      owner {
        id
        company
        name
      }
    }
  }
`;

export default GET_MESSAGES;
