import * as React from "react";
import styled from "styled-components";
import { Icon } from "./Icon";

type Props = {
  preview: string;
  onRemove: (index: number) => void;
  index: number;
};

const Preview = styled((props) => <div {...props} />)`
  width: 100px;
  height: 100px;
  background: url(${(props) => props.preview});
  border-radius: 6px;
  background-size: cover;
  position: relative;
  margin-right: 10px;
`;

export const ImagePreview: React.ComponentType<Props> = ({
  preview,
  onRemove,
  index,
}) => {
  return (
    <Preview preview={preview}>
      <div
        className="absolute top-1 right-1 bg-white rounded-xl w-6 h-6 flex items-center justify-center"
        onClick={() => onRemove(index)}
      >
        <Icon icon={"close"} color={"text-primary-grey"} />
      </div>
    </Preview>
  );
};
