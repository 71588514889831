import { gql } from "@apollo/client";

const GET_LATEST_PAYMENT = gql`
  query getLatestPayment {
    getLatestPayment {
      cardHolder
      cardBrand
      lastFourDigits
      expiration
    }
  }
`;

export default GET_LATEST_PAYMENT;
