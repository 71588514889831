import React from "react";
import styled from "styled-components";
import { colors } from "../../utilities/variables";

type Props = {
  title: string;
  onClick: () => void;
  isSelected: boolean;
};

const StyledLink = styled((props) => <div {...props} />)`
  color: ${(props) => (props.$selected ? colors.white : colors.black)};
  background-color: ${(props) =>
    props.$selected ? colors.black : "transparent"};
  justify-content: center;
  padding: 8px 0;
  border-radius: 60px;
  border: 1px solid
    ${(props) => (props.$selected ? colors.black : colors.lightGrey)};
  width: 150px;
  @media (max-width: 480px) {
    width: 100%;
  }
  display: flex;
  cursor: pointer;
`;

export const RoundedFilterButton: React.ComponentType<Props> = ({
  title,
  onClick,
  isSelected,
}) => {
  return (
    <StyledLink
      className={"font-medium"}
      onClick={onClick}
      $selected={isSelected}
    >
      {title}
    </StyledLink>
  );
};
