import { gql } from "@apollo/client";

const GET_THREADS = gql`
  query getThreads($id: String!) {
    getThreads(uuid: $id) {
      id
      createdAt
      customer {
        company
        image
      }
      lastMessage
      lastMessageCreatedAt
    }
  }
`;

export default GET_THREADS;
