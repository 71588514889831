import { ApolloError, FetchResult } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { AvatarIcon } from "../../component/atoms/AvatarIcon";
import { Card } from "../../component/atoms/Card";
import { OutlinedButton } from "../../component/atoms/OutlinedButton";
import { PageTitle } from "../../component/atoms/PageTitle";
import { RoundedFilterButton } from "../../component/atoms/RoundedFilterButton";
import { PostItemList } from "../../component/molecules/PostItemList";
import { AppLayout } from "../../component/organisms/AppLayout";
import GET_POSTS_WITH_MY_THREAD from "../../query/Message/getPostsWithMyThreads";
import GET_POSTS_WITH_THREAD from "../../query/Message/getPostsWithThread";
import { Post } from "../../types/Post";
import { client } from "../../utilities/Apollo";

type Props = {};
export const Messages: React.ComponentType<Props> = () => {
  const [loadingData, setLoadingData] = React.useState(true);
  const [data, setData] = React.useState<Post[] | []>([]);
  const [filter, setFilter] = React.useState<string>("mine");
  const history = useHistory();

  const getThreadPosts = React.useCallback(async (filter) => {
    setFilter(filter);
    setLoadingData(true);
    if (filter === "mine") {
      await client
        .query({ query: GET_POSTS_WITH_THREAD })
        .then((response) => onDataSuccess(response))
        .catch((error) => onDataError(error));
    } else {
      await client
        .query({ query: GET_POSTS_WITH_MY_THREAD })
        .then((response) => onMyDataSuccess(response))
        .catch((error) => onDataError(error));
    }
  }, []);

  const onMyDataSuccess = (response: FetchResult) => {
    setData(response?.data?.getPostsWithMyThreads);
    setLoadingData(false);
  };

  const onDataSuccess = (response: FetchResult) => {
    setData(response?.data?.getPostsWithThread);
    setLoadingData(false);
  };

  const onDataError = (error: ApolloError) => {
    console.log(error);
  };

  React.useEffect(() => {
    async function fetchData() {
      await getThreadPosts(filter);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getThreadPosts]);

  return (
    <AppLayout>
      <PageTitle title="Beskeder" />
      <div className="flex flex-wrap gap-5 mt-4">
        <RoundedFilterButton
          isSelected={filter === "mine"}
          title={"Mine annoncer"}
          onClick={() => getThreadPosts("mine")}
        />
        <RoundedFilterButton
          isSelected={filter === "andre"}
          title={"Andre annoncer"}
          onClick={() => getThreadPosts("andre")}
        />
      </div>
      {loadingData ? (
        <div className={"text-center pt-8"}>
          <p>Vent venligst, data indlæses…</p>
          <div className={"text-primary-green mt-2"}>
            <CircularProgress color="inherit" />
          </div>
        </div>
      ) : (
        <div className={"mt-5"}>
          {data.length > 0 && (
            <Card
              width={"w-full"}
              padding={2}
              className={"mt-5"}
              rounded={"rounded-lg"}
            >
              {data.map((post, index) => {
                let link;
                if (filter === "mine") {
                  link = "/messages/thread/" + post.uuid;
                } else {
                  link = "/messages/post/" + post.uuid;
                }
                return (
                  <PostItemList
                    link={link}
                    post={post}
                    key={post.id}
                    index={index}
                    isLast={data.length - 1 === index}
                  />
                );
              })}
            </Card>
          )}
          {data.length === 0 && (
            <div className="text-center flex flex-col items-center">
              <AvatarIcon icon={"search"} size={"text-5xl"} />
              <p className="font-semibold text-2xl py-3">Ingen annoncer</p>
              <p className="text-grey-primary mb-3">
                Du har ingen annoncer gemt som klædde lige nu
              </p>
              <OutlinedButton
                color={"text-primary-green"}
                title="Opret en ny annonce"
                onClick={() => history.push("/post-item")}
              />
            </div>
          )}
        </div>
      )}
    </AppLayout>
  );
};
