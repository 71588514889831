import React from "react";
import styled from "styled-components";
import BackgroundImage from "../../assets/background.png";
import { NavBar } from "../../component/atoms/NavBar";
import { Footer } from "../atoms/Footer";

type Props = {
  padding?: number;
};

const Wrapper = styled.div`
  background-color: #edf1f6;
  height: 100vh;
`;

const PromoText = styled.div``;

const Layout = styled.div`
  display: flex;
  height: calc(100% - 64px);
`;

const LeftSide = styled.div`
  background-image: url("${BackgroundImage}");
  position: relative;
  &::after {
    display: block;
    position: absolute;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.9) 100%
    );
    bottom: 0;
    left: 0;
    right: 0;
    height: 75vh;
    width: 100%;
    content: "";
  }
`;

const RightSide = styled.div``;

export const LoginLayout: React.ComponentType<Props> = ({
  children,
  padding,
}) => {
  return (
    <Wrapper>
      <NavBar showLogin={false} />
      <Layout>
        <div className={"sm:w-2/5 sm:block hidden"}>
          <LeftSide className="w-full h-full bg-gray-400 bg-cover">
            <div className={"flex h-full items-end"}>
              <PromoText
                className={"pb-16 pl-10 text-4xl text-white z-40 font-light"}
              >
                <div>
                  <span className={"font-bold"}>Find de bedste deals</span> fra
                  kollegaer i autobranchen
                </div>
                <div className={"text-base font-normal mt-4"}>
                  Engroshandel kun for autobranchen
                </div>
              </PromoText>
            </div>
          </LeftSide>
        </div>
        <div className={"sm:w-3/5 h-full w-full"}>
          <RightSide
            className={"flex flex-col items-center h-full justify-center"}
          >
            {children}
            <Footer />
          </RightSide>
        </div>
      </Layout>
    </Wrapper>
  );
};
