import React from "react";
import styled from "styled-components";
import { colors } from "../../utilities/variables";
import { Button } from "./Button";
import { CheckItem } from "./CheckItem";
import classNames from "classnames";

type Props = {
  backgroundColor?: string;
  fontColor?: string;
  price?: string;
  title?: string;
  subtitle?: string;
  borderColor?: string;
  priceColor?: string;
  checkItems?: string[];
  iconBackground?: string;
  onButtonClick: () => void;
};

const Wrapper = styled((props) => <div {...props} />)`
  background-color: ${(props) => props.$background};
  color: ${(props) => props.$color};
  border: 1px solid ${(props) => props.$border};
  border-radius: 8px;
  padding: 20px;
  max-width: 300px;
  width: 100%;
`;

export const MembershipCard: React.ComponentType<Props> = ({
  backgroundColor = colors.black,
  fontColor = colors.white,
  title = "Premium",
  price = "99 kr.",
  subtitle = "Advanced functions included",
  borderColor = colors.black,
  priceColor = "text-white",
  checkItems = [
    "Se annonce overskrifter",
    "Se annonce detaljer",
    "Opret annoncer",
    "Kontakt sælger",
    "Se service katalog",
  ],
  iconBackground = colors.white,
  onButtonClick,
}) => {
  return (
    <Wrapper
      $background={backgroundColor}
      $color={fontColor}
      $border={borderColor}
    >
      <div className="flex flex-col justify-items-stretch h-full">
        <div className="flex-grow">
          <div className={"flex justify-between items-center mb-7"}>
            <div className="text-xl font-semibold">{title}</div>
            <div className={classNames("font-medium", priceColor)}>
              {price}/md.
            </div>
          </div>

          {checkItems.map((item, index) => {
            return (
              <CheckItem
                key={index}
                iconBackground={iconBackground}
                iconColor={"text-primary-blue"}
                title={item}
              />
            );
          })}
        </div>
        <div className={"pt-3"}>
          <Button
            loading={false}
            primary={true}
            title={"Vælgmedlemskab"}
            onClick={onButtonClick}
          />
        </div>
      </div>
    </Wrapper>
  );
};
