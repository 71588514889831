import React from "react";
import { Icon } from "./Icon";
import styled from "styled-components";
import { colors } from "../../utilities/variables";

type Props = {
  title: string;
  iconBackground?: string;
  iconColor?: string;
};

const CheckAvatar = styled((props) => <div {...props} />)`
  background-color: ${(props) => props.$iconBackground};
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckItem: React.ComponentType<Props> = ({
  title,
  iconBackground = colors.lightGreen,
  iconColor = "text-primary-green",
}) => {
  return (
    <div className={"flex items-center mb-3"}>
      <CheckAvatar $iconBackground={iconBackground}>
        <Icon icon={"check"} color={iconColor} />
      </CheckAvatar>

      <div className={"pl-3"}>{title}</div>
    </div>
  );
};
