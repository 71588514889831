export const colors = {
  white: "#FFFFFF",
  black: "#1C2134",
  grey: "#8792AF",
  inputField: "#EDF1F6",
  lightGrey: "#DCE6F3",
  primary: "#25D17E",
  nearlyBlack: "#1C2134",
  fadedGrey: "#f6f7f9",
  blue: "#3657CA",
  error: "#FF4343",
  lightRed: "#ff434326",
  lightGreen: "#dff5ea",
  lightBlue: "#3657ca0d",
  lightestGreen: "#f4fcf8",
  profileGreen: "#2CC079",
};

export const translations = {
  required: "Feltet er påkrævet",
};
