import { gql } from "@apollo/client";

const GET_FORM_DATA = gql`
  query getFormData {
    getCategories {
      id
      uuid
      title
    }
    getBrands {
      id
      uuid
      title
    }
  }
`;

export default GET_FORM_DATA;
