import { Container } from "@material-ui/core";
import React from "react";
import { Card } from "../../component/atoms/Card";
import { AppLayout } from "../../component/organisms/AppLayout";
import { useSelector } from "../../store/hooks";
import { FormTitleSubtitle } from "../../component/atoms/FormTitleSubtitle";
import { translations } from "../../utilities/variables";
import * as yup from "yup";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "../../component/atoms/Button";
import { ApolloError, FetchResult } from "@apollo/client";
import { client } from "../../utilities/Apollo";
import { ProfileHeader } from "../../component/molecules/ProfileHeader";
import { ProfileMenu } from "../../component/molecules/ProfileMenu";
import GET_COWORKERS_COUNT from "../../query/User/getCoworkersCount";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { colors } from "../../utilities/variables";
import InputAdornment from "@mui/material/InputAdornment";
import { Icon } from "../../component/atoms/Icon";
import { OutlinedButton } from "../../component/atoms/OutlinedButton";
import INVITE_COWORKERS from "../../query/User/inviteCoworkers";
import Alert, { Color } from "@material-ui/lab/Alert";
import { Hint } from "../../component/atoms/Hint";
import { useHistory } from "react-router-dom";

type Props = {};

type Email = {
  value: string;
};

const schema = yup.object().shape({
  numberOfInvites: yup.string().required("Number of tickets is required"),
  emails: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .email("Email is Invalid")
        .required(translations.required),
    })
  ),
});

type ExtraProps = {
  radius: number;
};

const StyledTextField = styled((props: TextFieldProps & ExtraProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme, radius }) => ({
  width: "100%",
  fontFamily: "Poppins",
  "& .MuiFilledInput-root": {
    fontFamily: "Poppins",
    border: "none",
    overflow: "hidden",
    borderRadius: radius,
    backgroundColor: colors.inputField,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: colors.inputField,
    },
    "&.Mui-focused": {
      backgroundColor: colors.inputField,
    },
    "&.Mui-disabled": {
      backgroundColor: colors.inputField,
    },
  },
  "& .MuiInputLabel-root": {
    color: colors.grey,
    fontFamily: "Poppins",
    "&.Mui-focused": {
      color: colors.grey,
    },
  },
}));

export const InviteCoworkers: React.ComponentType<Props> = () => {
  const { user } = useSelector((state) => state.user);

  const [loading, setLoading] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(true);
  const [invitesLeft, setInvitesLeft] = React.useState<number>(5);
  const [error, setError] = React.useState<string | null>("");
  const [severity, setSeverity] = React.useState<Color>("error");
  const history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "emails" as never,
  });

  const getCoworkersCount = React.useCallback(async () => {
    setLoadingData(true);
    await client
      .query({ query: GET_COWORKERS_COUNT })
      .then((response) => onDataSuccess(response))
      .catch((error) => onDataError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDataSuccess = (response: FetchResult) => {
    setInvitesLeft(5 - response?.data?.getCoworkersCount);
    setLoadingData(false);
  };

  const onDataError = (error: ApolloError) => {
    console.log(error);
  };

  React.useEffect(() => {
    async function fetchData() {
      await getCoworkersCount();
    }
    fetchData();
    setValue("numberOfInvites", 5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCoworkersCount]);

  const handleOnSubmit = async (data: any) => {
    setLoading(true);
    const { emails } = data;
    const filteredArr = emails.map((value: Email) => {
      return { email: value.value };
    });

    if (filteredArr.length === 0) {
      setLoading(false);
      return;
    }

    await client
      .mutate({
        mutation: INVITE_COWORKERS,
        variables: {
          input: filteredArr,
        },
      })
      .then((response) => onSuccess(response, filteredArr))
      .catch((error) => onError(error));
  };

  const onSuccess = async (response: FetchResult, emails: [string]) => {
    setLoading(false);
    setError("Invites sent successfully");
    setSeverity("success");
    remove();
  };

  const onError = (error: ApolloError) => {
    setLoading(false);
    setSeverity("error");
    setError(error.graphQLErrors[0].message);
  };

  const addMore = () => {
    append({ value: "" });
    setInvitesLeft((prevInvites) => prevInvites - 1);
  };

  const removeEmail = (index: number) => {
    remove(index);
    setInvitesLeft((prevInvites) => prevInvites + 1);
  };

  React.useEffect(() => {
    if (user.id && user.activeMembership.type === "free") {
      history.push("/profile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <AppLayout fluid={true}>
      <ProfileHeader user={user} />
      <Container maxWidth={"lg"} className={"pb-5"}>
        <div className={"mt-5"}>
          <ProfileMenu user={user} />
          <Card
            width={"w-full"}
            padding={2}
            className={"mt-5"}
            rounded={"rounded-lg"}
          >
            <div>
              <div>
                <FormTitleSubtitle
                  title={"Invitér medarbejder"}
                  fontWeight="font-semibold"
                />
                {error && (
                  <Alert severity={severity} className={"my-3"}>
                    {error}
                  </Alert>
                )}
                {!loadingData && (
                  <form onSubmit={handleSubmit((data) => handleOnSubmit(data))}>
                    <div className={"sm:w-1/2 w-full pb-3"}>
                      <Hint title={"Invitation tilbage: " + invitesLeft} />
                      <ul>
                        {fields.map((item, index) => (
                          <li key={index}>
                            <Controller
                              //@ts-ignore
                              name={`emails.${index}.value`}
                              //@ts-ignore
                              defaultValue={item.value}
                              control={control}
                              render={({ field }) => (
                                <StyledTextField
                                  label={"E-mail"}
                                  variant={"filled"}
                                  radius={2}
                                  InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <span
                                          className={"cursor-pointer"}
                                          onClick={() => removeEmail(index)}
                                        >
                                          <Icon
                                            icon={"close"}
                                            size={"text-2xl"}
                                          />
                                        </span>
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={
                                    errors.emails?.[index]?.value.message
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errors.emails?.[index]?.value.message || " "
                                  }
                                  {...field}
                                />
                              )}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="border-b pb-5">
                      <div className="sm:w-1/5 w-full">
                        {invitesLeft > 0 && (
                          <OutlinedButton
                            onClick={() => addMore()}
                            title={"Tilføj flere"}
                            color={"text-primary-green"}
                            defineHeight={false}
                          />
                        )}
                      </div>
                    </div>
                    <div className="pt-5">
                      <Button
                        loading={loading}
                        type={"submit"}
                        title={"Invitér"}
                        onClick={() => {}}
                      />
                    </div>
                  </form>
                )}
              </div>
            </div>

            <div className="sm:w-1/4 w-full pt-3"></div>
          </Card>
        </div>
      </Container>
    </AppLayout>
  );
};
