import { gql } from "@apollo/client";

const CHECK_CVR_NUMBER = gql`
  mutation checkCvrNumber($cvr: String!) {
    checkCvrNumber: checkCvrNumber(cvr: $cvr) {
      name
      email
      phone
      address
      zipcode
      city
      owner
    }
  }
`;

export default CHECK_CVR_NUMBER;
