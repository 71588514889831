import { gql } from "@apollo/client";

const UPDATE_CUSTOMER_PASSWORD = gql`
  mutation customerResetPassword($oldPassword: String!, $newPassword: String!) {
    customerResetPassword: customerResetPassword(
      input: { oldPassword: $oldPassword, newPassword: $newPassword }
    )
  }
`;

export default UPDATE_CUSTOMER_PASSWORD;
