import React from "react";
import { Thread } from "../../types/Thread";
import classNames from "classnames";
import { formatDistance } from "date-fns";
import { Icon } from "./Icon";
import styled from "styled-components";
import { colors } from "../../utilities/variables";
import { da } from "date-fns/locale";

type Props = {
  thread: Thread;
  isLast: boolean;
  isActive: boolean;
  onClick: (thread: Thread) => void;
};

const ActiveIcon = styled.div`
  width: 11px;
  height: 11px;
  background-color: ${colors.blue};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 4px solid rgba(54, 87, 202, 0.15);
`;

export const ThreadChatItem: React.ComponentType<Props> = ({
  thread,
  isLast,
  isActive,
  onClick,
}) => {
  return (
    <div
      className={classNames("flex justify-between py-4 cursor-pointer", {
        "border-b": !isLast,
      })}
      onClick={() => onClick(thread)}
    >
      <div>
        <p className="font-medium">{thread.customer.company}</p>
        <p
          className={classNames("text-sm font-medium mt-1", {
            "text-grey-primary": !isActive,
            "text-primary-blue": isActive,
          })}
        >
          {formatDistance(new Date(thread.lastMessageCreatedAt), new Date(), {
            addSuffix: true,
            locale: da,
          })}
        </p>
      </div>

      <div className="flex flex-col items-center">
        <Icon
          icon={"chevron-right"}
          size={"text-3xl"}
          color={"text-grey-primary"}
        />
        {isActive && <ActiveIcon className="mt-1" />}
      </div>
    </div>
  );
};
