import { gql } from "@apollo/client";

const UPDATE_CUSTOMER_IMAGE = gql`
  mutation updateCustomerImage($image: String!) {
    updateCustomerImage: updateCustomerImage(input: { image: $image }) {
      id
      uuid
      company
      name
      city
      email
      active
      address
      zip
      image
      phone
      role
      ownerId
      invoiceContactPerson
      paymentType
      invoiceContactEmail
      activeMembership {
        id
        type
        isExpired
        dateTo
        awaitingCancellation
      }
    }
  }
`;

export default UPDATE_CUSTOMER_IMAGE;
