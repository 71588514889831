import React from "react";

type Props = {
  title: string;
  value?: string;
};

export const PostDetailItem: React.ComponentType<Props> = ({
  title,
  value,
}) => {
  return (
    <div className={"flex py-1"}>
      <div className={"w-28 text-grey-primary"}>{title}</div>
      <div>{value}</div>
    </div>
  );
};
