import { gql } from "@apollo/client";

const SEARCH_ANONYMOUS_POSTS = gql`
  query searchAnonymousPosts(
    $brands: [Int]
    $categories: [Int]
    $regions: [String]
    $text: String
    $customer: Boolean
  ) {
    searchAnonymousPosts(
      input: {
        brands: $brands
        categories: $categories
        text: $text
        region: $regions
        customer: $customer
      }
    ) {
      id
      uuid
      title
      price
      createdAt
      city
      zip
      askForBids
      sold
      images {
        url
      }
    }
  }
`;

export default SEARCH_ANONYMOUS_POSTS;
