import React from "react";
import { translations } from "../../utilities/variables";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputField } from "../atoms/InputField";
import { Button } from "../atoms/Button";
import { ValidCvrCompany } from "../../types/Customer";

type Props = {
  afterFormSubmit: (data: any) => void;
  validCvrCompany: ValidCvrCompany | null;
};

interface IFormInput {
  email: string;
  company: string;
  address: string;
  name: string;
  city: string;
  phone: string;
  zip: string;
}

const schema = yup.object().shape({
  email: yup.string().required(translations.required).email("Ugyldig email"),
  company: yup.string().required(translations.required),
  name: yup.string().required(translations.required),
  address: yup.string().required(translations.required),
  city: yup.string().required(translations.required),
  phone: yup.string().required(translations.required),
  zip: yup.string().required(translations.required),
});

export const ContactForm: React.ComponentType<Props> = ({
  afterFormSubmit,
  validCvrCompany,
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setLoading(true);
    trigger();
    afterFormSubmit(data);
  };

  React.useEffect(() => {
    register("email", { required: true });
    register("company", { required: true });
    register("address", { required: true });
    register("city", { required: true });
    register("zip", { required: true });
    register("phone", { required: true });
    register("name", { required: true });
  }, [register]);

  React.useEffect(() => {
    setValue("company", validCvrCompany?.name ?? "");
    setValue("email", validCvrCompany?.email ?? "");
    setValue("address", validCvrCompany?.address ?? "");
    setValue("city", validCvrCompany?.city ?? "");
    setValue("zip", validCvrCompany?.zipcode ?? "");
    setValue("phone", validCvrCompany?.phone ?? "");
    setValue("name", validCvrCompany?.owner ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col"}>
      <InputField
        label={"Company"}
        defaultValue={validCvrCompany?.name ?? ""}
        name="company"
        error={errors.company?.message}
        onChange={async (e: any, data: any) => {
          setValue(e.target.name, e.target.value);
        }}
      />

      <InputField
        label={"Navn"}
        defaultValue={validCvrCompany?.owner ?? ""}
        name="name"
        error={errors.name?.message}
        onChange={async (e: any, data: any) => {
          setValue(e.target.name, e.target.value);
        }}
      />

      <InputField
        label={"Email"}
        defaultValue={validCvrCompany?.email ?? ""}
        name="email"
        error={errors.email?.message}
        onChange={async (e: any, data: any) => {
          setValue(e.target.name, e.target.value);
        }}
      />

      <InputField
        label={"Telefon"}
        defaultValue={validCvrCompany?.phone ?? ""}
        name="phone"
        error={errors.phone?.message}
        onChange={async (e: any, data: any) => {
          setValue(e.target.name, e.target.value);
        }}
      />

      <InputField
        label={"Addresse"}
        defaultValue={validCvrCompany?.address ?? ""}
        name="address"
        error={errors.address?.message}
        onChange={async (e: any, data: any) => {
          setValue(e.target.name, e.target.value);
        }}
      />

      <InputField
        label={"By"}
        defaultValue={validCvrCompany?.city ?? ""}
        name="city"
        error={errors.city?.message}
        onChange={async (e: any, data: any) => {
          setValue(e.target.name, e.target.value);
        }}
      />

      <InputField
        label={"Postnummer"}
        defaultValue={validCvrCompany?.zipcode ?? ""}
        name="zip"
        error={errors.zip?.message}
        onChange={async (e: any, data: any) => {
          setValue(e.target.name, e.target.value);
        }}
      />

      <Button
        onClick={handleSubmit(onSubmit)}
        loading={loading}
        title={"Fortsæt"}
        primary={true}
      />
    </form>
  );
};
