import { gql } from "@apollo/client";

const CREATE_THREAD = gql`
  mutation createThread($postId: Int!) {
    createThread: createThread(postId: $postId) {
      id
      createdAt
      customer {
        id
        name
      }
    }
  }
`;

export default CREATE_THREAD;
