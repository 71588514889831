import React from "react";
import styled from "styled-components";
import { colors } from "../../utilities/variables";

type Props = {
  title: string;
  onClick: (e: any) => void;
  count: number;
};
const StyledFilterButton = styled((props) => <button {...props} />)`
  border: 1px solid ${colors.lightGrey};
  border-radius: 60px;
  width: 150px;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  background-color: ${(props) =>
    props.$isActive ? colors.nearlyBlack : "transparent"};
  color: ${(props) => (props.$isActive ? colors.white : colors.nearlyBlack)};
`;

export const FilterButton: React.ComponentType<Props> = ({
  title,
  onClick,
  count,
}) => {
  return (
    <StyledFilterButton onClick={onClick} $isActive={count > 0}>
      {title}
      {count > 0 && <span className="ml-2 text-xs">({count})</span>}
    </StyledFilterButton>
  );
};
