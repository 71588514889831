import { gql } from "@apollo/client";

const GET_THREAD_BY_POST = gql`
  query getThreadByPost($id: Int!) {
    getThreadByPost(id: $id) {
      id
      createdAt
      customer {
        id
        company
      }
    }
  }
`;

export default GET_THREAD_BY_POST;
