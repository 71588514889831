import { gql } from "@apollo/client";

const CREATE_CUSTOMER = gql`
  mutation createCustomer(
    $company: String!
    $cvr: String!
    $email: String!
    $password: String!
    $address: String!
    $city: String!
    $phone: String!
    $zip: String!
    $name: String!
  ) {
    createCustomer: createCustomer(
      input: {
        company: $company
        address: $address
        city: $city
        password: $password
        cvr: $cvr
        email: $email
        phone: $phone
        zip: $zip
        name: $name
      }
    )
  }
`;

export default CREATE_CUSTOMER;
