import React from "react";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import { colors } from "../../utilities/variables";
import { Customer } from "../../types/Customer";

type Props = {
  user: Customer;
};

const UserName = styled((props) => <div {...props} />)`
  color: ${colors.nearlyBlack};
  font-weight: 500;
  margin-left: 0.5rem;
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${colors.nearlyBlack} !important;
`;

export const UserAvatar: React.ComponentType<Props> = ({ user }) => {
  const getInitials = () => {
    if (user.image) {
      return <img src={user.image} alt={user.company} />;
    }
    const string = user.company;

    let names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  if (!user.id) {
    return null;
  }

  return (
    <div className={"flex items-center"}>
      <StyledAvatar>
        <span className={"text-sm font-medium font-Poppins"}>
          {getInitials()}
        </span>
      </StyledAvatar>
      <UserName className={"lg:block hidden"}>{user.company}</UserName>
    </div>
  );
};
