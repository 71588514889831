import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import { FilterButton } from "../atoms/FilterButton";
import { Brand } from "../../types/Post";
import { FilterItem } from "../atoms/FilterItem";
import { Button } from "../atoms/Button";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 260,
    border: "none",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const FooterButtons = styled((props) => <div {...props} />)(({ theme }) => ({
  boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.03)",
  padding: "8px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const FilterBody = styled((props) => <div {...props} />)(({ theme }) => ({
  maxHeight: "400px",
  padding: "1rem",
  overflowY: "auto",
}));

type Props = {
  brands: [Brand] | [];
  apply: (categories: [number]) => void;
  clear: () => void;
  count: number;
};
export const FilterBrands: React.ComponentType<Props> = ({
  brands,
  apply,
  clear,
  count,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedBrands, setSelectedBrands] = React.useState<[number] | []>([]);
  const [initialBrands, setInitialBrands] = React.useState<[Brand] | []>([]);
  const [filteredBrands, setFilteredBrands] = React.useState<[Brand] | []>([]);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setInitialBrands(brands);
    setFilteredBrands(brands);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClick = (id: number) => {
    if (selectedBrands.includes(id as never)) {
      const newSelectedBrands = selectedBrands.filter((f) => f !== id) as [
        number
      ];
      setSelectedBrands(newSelectedBrands);
    } else {
      const newSelectedBrands = [...selectedBrands, id] as [number];

      setSelectedBrands(newSelectedBrands);
    }
  };

  const applyFilters = () => {
    handleClose();
    setFilteredBrands(initialBrands);
    apply(selectedBrands as [number]);
  };

  const clearFilters = () => {
    handleClose();
    setSelectedBrands([]);
    setFilteredBrands(initialBrands);
    clear();
  };

  const filterList = (event: any) => {
    var newUpdatedList = initialBrands.filter(function (item) {
      return (
        item.title.toLowerCase().search(event.target.value.toLowerCase()) !== -1
      );
    });

    setFilteredBrands(newUpdatedList as [Brand]);
  };

  return (
    <div>
      <FilterButton onClick={handleClick} title={"Mærke"} count={count} />

      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="px-4 pt-4">
          <div className="bg-grey-input py-2 px-4 rounded-full">
            <input
              type="text"
              className="outline-none bg-transparent"
              placeholder="Søg"
              onChange={filterList}
            />
          </div>
        </div>

        <FilterBody>
          {filteredBrands.map((brand) => {
            return (
              <FilterItem
                id={brand.id}
                key={brand.id}
                title={brand.title}
                onClick={handleOnClick}
                isSelected={selectedBrands.includes(brand.id as never)}
              />
            );
          })}
        </FilterBody>
        <FooterButtons>
          <div>
            <span
              onClick={clearFilters}
              className="font-medium text-sm cursor-pointer"
            >
              Ryd
            </span>
          </div>
          <div>
            <Button
              radius={60}
              useMargin={false}
              fontSize={"text-sm"}
              defineHeight={false}
              loading={false}
              onClick={applyFilters}
              title={"Søg"}
              primary={true}
              width={110}
            />
          </div>
        </FooterButtons>
      </StyledMenu>
    </div>
  );
};
