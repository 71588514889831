import { Container } from "@material-ui/core";
import React from "react";
import { Card } from "../../component/atoms/Card";
import { AppLayout } from "../../component/organisms/AppLayout";
import { useSelector } from "../../store/hooks";
import { FormTitleSubtitle } from "../../component/atoms/FormTitleSubtitle";
import { ApolloError, FetchResult } from "@apollo/client";
import { client } from "../../utilities/Apollo";
import { ProfileHeader } from "../../component/molecules/ProfileHeader";
import { ProfileMenu } from "../../component/molecules/ProfileMenu";
import { colors } from "../../utilities/variables";
import { Hint } from "../../component/atoms/Hint";
import { useHistory } from "react-router-dom";
import { ChipComponent } from "../../component/atoms/Chip";
import { InputField } from "../../component/atoms/InputField";
import { Payment } from "../../types/Customer";
import GET_LATEST_PAYMENT from "../../query/User/getLatestPayment";

type Props = {};

export const PaymentMethods: React.ComponentType<Props> = () => {
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<Payment | null>(null);
  const history = useHistory();

  React.useEffect(() => {
    if (user.id && user.activeMembership.type === "free") {
      history.push("/profile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getLatestPayment = React.useCallback(async () => {
    setLoading(true);
    await client
      .query({ query: GET_LATEST_PAYMENT })
      .then((response) => onDataSuccess(response))
      .catch((error) => onDataError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDataSuccess = (response: FetchResult) => {
    setData(response?.data?.getLatestPayment);
    setLoading(false);
  };

  const onDataError = (error: ApolloError) => {
    console.log(error);
  };

  React.useEffect(() => {
    async function fetchData() {
      await getLatestPayment();
    }
    fetchData();
  }, [getLatestPayment]);

  return (
    <AppLayout fluid={true}>
      <ProfileHeader user={user} />
      <Container maxWidth={"lg"} className={"pb-5"}>
        <div className={"mt-5"}>
          <ProfileMenu user={user} />
          <Card
            width={"w-full"}
            padding={2}
            className={"mt-5"}
            rounded={"rounded-lg"}
          >
            {!loading && (
              <div>
                <div className="border-b">
                  <FormTitleSubtitle
                    title={"Betalingsmetode"}
                    fontWeight="font-semibold"
                  />
                </div>
                <div className="py-5 border-b">
                  <div className="flex justify-between">
                    <div className="text-sm font-medium">Kreditkort</div>
                    {user.paymentType === "credit_card" && (
                      <div>
                        <ChipComponent
                          backgroundColor={colors.lightGreen}
                          textColor={colors.primary}
                          title={"Aktiv"}
                        />
                      </div>
                    )}
                  </div>
                  {user.paymentType === "credit_card" && (
                    <div>
                      <div
                        className={
                          "mt-4 rounded-lg shadow flex items-center justify-between p-3"
                        }
                      >
                        <div>
                          <p className={"font-medium"}>{data?.cardHolder}</p>
                          <p className={"font-medium my-2"}>
                            **** **** **** {data?.lastFourDigits}
                          </p>
                          <p className={"text-sm text-grey-primary"}>
                            {data?.expiration}
                          </p>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="py-5 border-b">
                  <div className="flex justify-between">
                    <div className="text-sm font-medium">Faktura</div>
                    {user.paymentType === "invoice" && (
                      <div>
                        <ChipComponent
                          backgroundColor={colors.lightGreen}
                          textColor={colors.primary}
                          title={"Aktiv"}
                        />
                      </div>
                    )}
                  </div>
                  {user.paymentType === "invoice" && (
                    <div className="mt-4">
                      <div className="grid grid-cols-2 gap-6">
                        <InputField
                          label={"Kontaktperson"}
                          name={"contactPerson"}
                          disabled
                          onChange={() => {}}
                          defaultValue={user.invoiceContactPerson}
                        />
                        <InputField
                          label={"Email"}
                          name={"contactEmail"}
                          disabled
                          onChange={() => {}}
                          defaultValue={user.invoiceContactEmail}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="py-5 border-b">
                  <div className="flex justify-between">
                    <div className="text-sm font-medium">Apple Pay</div>
                    {user.paymentType === "apple_pay" && (
                      <div>
                        <ChipComponent
                          backgroundColor={colors.lightGreen}
                          textColor={colors.primary}
                          title={"Aktiv"}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="py-5 border-b">
                  <div className="flex justify-between">
                    <div className="text-sm font-medium">Google Pay</div>
                    {user.paymentType === "google_pay" && (
                      <div>
                        <ChipComponent
                          backgroundColor={colors.lightGreen}
                          textColor={colors.primary}
                          title={"Aktiv"}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {user.paymentType === "apple_pay"}
                <div className="mt-4">
                  <Hint
                    title={
                      "Apple Pay styres på din mobil med din personlig Apple ID"
                    }
                  />
                </div>
              </div>
            )}
          </Card>
        </div>
      </Container>
    </AppLayout>
  );
};
