import { gql } from "@apollo/client";

const MAKE_PURCHASE = gql`
  mutation makeWebPurchase(
    $paymentType: String!
    $amount: Int!
    $status: String!
  ) {
    makeWebPurchase: makeWebPurchase(
      input: { paymentType: $paymentType, amount: $amount, status: $status }
    )
  }
`;

export default MAKE_PURCHASE;
