import { gql } from "@apollo/client";

const GET_POSTS_WITH_THREAD = gql`
  query {
    getPostsWithThread {
      id
      uuid
      title
      price
      sold
      createdAt
      images {
        id
        url
      }
      threads {
        uuid
        customer {
          company
        }
        createdAt
      }
    }
  }
`;

export default GET_POSTS_WITH_THREAD;
