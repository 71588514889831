import { gql } from "@apollo/client";

const GET_ANONYMOUS_POST = gql`
  query getAnonymousPost($uuid: String!) {
    getAnonymousPost(uuid: $uuid) {
      id
      uuid
      title
      description
      askForBids
      allowChat

      status
      images {
        id
        url
      }
      price
      createdAt
      address
      city
      zip
      sold
      active
      categories {
        id
        title
      }
      brand {
        id
        title
      }
      customer {
        id
        company
        address
        city
        zip
        email
        phone
        image
      }
    }
  }
`;

export default GET_ANONYMOUS_POST;
