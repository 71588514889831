import React from "react";
import { translations } from "../../utilities/variables";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputField } from "../atoms/InputField";
import { Button } from "../atoms/Button";

type Props = {
  afterFormSubmit: (password: string) => void;
  loading: boolean;
};

interface IFormInput {
  password: string;
  passwordConfirmation: string;
}

const schema = yup.object().shape({
  password: yup.string().required(translations.required),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords skal være ens"),
});

export const PasswordForm: React.ComponentType<Props> = ({
  afterFormSubmit,
  loading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    trigger();
    afterFormSubmit(data.password);
  };

  React.useEffect(() => {
    register("password", { required: true });
    register("passwordConfirmation");
  }, [register]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col"}>
      <InputField
        label={"Password"}
        defaultValue=""
        name="password"
        type={"password"}
        error={errors.password?.message}
        onChange={async (e: any, data: any) => {
          setValue(e.target.name, e.target.value);
        }}
      />

      <InputField
        label={"Gentag password"}
        defaultValue=""
        type={"password"}
        name="passwordConfirmation"
        error={errors.passwordConfirmation?.message}
        onChange={async (e: any, data: any) => {
          setValue(e.target.name, e.target.value);
        }}
      />

      <Button
        onClick={handleSubmit(onSubmit)}
        loading={loading}
        title={"Opret konto"}
        primary={true}
      />
    </form>
  );
};
