import { CircularProgress } from "@material-ui/core";
import React from "react";
import { Icon } from "./Icon";

type Props = {
  loading: boolean;
  isFavorite: boolean;
  handlePostFavorite: () => void;
  color?: string;
  filledColor?: string;
  loadingColor?: string;
};

export const FavoriteIcon: React.ComponentType<Props> = ({
  isFavorite,
  loading,
  handlePostFavorite,
  color = "text-white",
  filledColor = "text-white",
  loadingColor = "text-whote",
}) => {
  return (
    <div onClick={handlePostFavorite} className="cursor-pointer">
      {loading ? (
        <div className={loadingColor}>
          <CircularProgress color="inherit" size={20} />
        </div>
      ) : (
        <Icon
          icon={isFavorite ? "filled-star" : "star"}
          color={isFavorite ? filledColor : color}
          size={"text-2xl"}
        />
      )}
    </div>
  );
};
