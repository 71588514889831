import React, { useState, useEffect } from "react";
import { client, publicClient } from "../../utilities/Apollo";
import Alert from "@material-ui/lab/Alert";
import { useForm, SubmitHandler } from "react-hook-form";
import { ApolloError, FetchResult } from "@apollo/client";
import { Card } from "../../component/atoms/Card";
import { translations } from "../../utilities/variables";
import { InputField } from "../../component/atoms/InputField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "../../component/atoms/Button";
import { TitleWithSubtitle } from "../../component/atoms/TitleWithSubtitle";
import { LoginLayout } from "../../component/organisms/LoginLayout";
import { useLocation } from "react-router-dom";
import ACTIVATE_COWORKER from "../../query/Auth/activateCoworker";
import GET_CURRENT_USER from "../../query/User/getCurrent";
import { useDispatch } from "../../store/hooks";
import { userSlice } from "../../store/slices/userSlice";
import { useHistory } from "react-router-dom";

type Props = {};

interface IFormInput {
  name: string;
  password: string;
  passwordConfirmation: string;
}

const schema = yup.object({
  name: yup.string().required(translations.required),
  password: yup.string().required(translations.required),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords skal være ens"),
});

export const ActivateCoworker: React.ComponentType<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!query.get("u")) {
      setError("Token not provided, check the email link");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    trigger();
    await activateCoworker(data);
  };

  const activateCoworker = async (data: any) => {
    setLoading(true);
    setError(null);
    await publicClient
      .mutate({
        mutation: ACTIVATE_COWORKER,
        variables: {
          token: query.get("u"),
          password: data.password,
          name: data.name,
        },
      })
      .then((response) => onActivateSuccess(response))
      .catch((error) => onSetNewError(error));
  };

  const onActivateSuccess = async (response: FetchResult) => {
    let token = response?.data?.activateCoworker;
    if (token) {
      localStorage.setItem("token", token);
      await getCurrentUser();
    }
  };

  const onSetNewError = (error: ApolloError) => {
    setLoading(false);
    setError(error.graphQLErrors[0].message);
  };

  const getCurrentUser = async () => {
    await client
      .query({ query: GET_CURRENT_USER })
      .then((response: FetchResult) => onGetCurrentUserSuccess(response))
      .catch(() => onGetCurrentError());
  };

  const onGetCurrentUserSuccess = async (response: FetchResult) => {
    setLoading(false);
    dispatch(
      userSlice.actions.put({
        initialized: true,
        user: response?.data?.currentCustomer,
      })
    );

    history.push("/listings");
  };

  const onGetCurrentError = () => {
    setLoading(false);
  };

  React.useEffect(() => {
    register("name", { required: true });
    register("password", { required: true });
    register("passwordConfirmation");
  }, [register]);

  return (
    <LoginLayout>
      <Card>
        <TitleWithSubtitle
          title={"Medarbejder konto aktivering"}
          subtitle={``}
        />
        {error && (
          <Alert severity="error" className={"my-3"}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col"}>
          <InputField
            label={"Navn"}
            defaultValue=""
            name="name"
            type={"text"}
            error={errors.name?.message}
            onChange={async (e: any, data: any) => {
              setValue(e.target.name, e.target.value);
            }}
          />
          <InputField
            label={"Nyt password"}
            defaultValue=""
            name="password"
            type={"password"}
            error={errors.password?.message}
            onChange={async (e: any, data: any) => {
              setValue(e.target.name, e.target.value);
            }}
          />

          <InputField
            label={"Nyt password igen"}
            defaultValue=""
            type={"password"}
            name="passwordConfirmation"
            error={errors.passwordConfirmation?.message}
            onChange={async (e: any, data: any) => {
              setValue(e.target.name, e.target.value);
            }}
          />

          <Button
            onClick={handleSubmit(onSubmit)}
            loading={loading}
            title={"Bekræft"}
            primary={true}
          />
        </form>
      </Card>
    </LoginLayout>
  );
};
