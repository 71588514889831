import React from "react";
import styled from "styled-components";
import { colors } from "../../utilities/variables";

type Props = {
  title: string;
};

const Wrapper = styled((props) => <div {...props} />)`
  background-color: ${(props) => props.background};
  text-align: left;
  padding: 8px 16px;
  color: ${(props) => props.text};
  font-weight: 500;
  font-size: 13px;
  border-radius: 8px;
`;

export const Hint: React.ComponentType<Props> = ({ title }) => {
  return (
    <Wrapper background={colors.lightBlue} text={colors.blue}>
      {title}
    </Wrapper>
  );
};
