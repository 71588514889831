import { gql } from "@apollo/client";

const GET_ANONYMOUS_POSTS = gql`
  query {
    getAnonymousPosts {
      id
      uuid
      title
      price
      createdAt
      city
      zip
      askForBids
      sold
      type
      categories {
        id
      }
      images {
        url
      }
    }
  }
`;

export default GET_ANONYMOUS_POSTS;
