import { createSlice } from "@reduxjs/toolkit";

import { objectReducerGenerator } from "../objectReducerGenerator";
import { storeClear } from "../actions";
import { Customer } from "../../types/Customer";

export interface UserState {
  initialized: boolean;
  user: Customer;
}

const initialState: UserState = {
  initialized: false,
  user: {
    id: 0,
    email: "",
    uuid: "",
    company: "",
    paymentType: "",
    cvr: "",
    city: "",
    active: false,
    phone: "",
    zip: "",
    address: "",
    image: undefined,
    name: "",
    ownerId: 0,
    invoiceContactPerson: "",
    invoiceContactEmail: "",
    activeMembership: {
      id: 0,
      awaitingCancellation: false,
      type: "",
      isExpired: false,
      dateTo: new Date(),
    },
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    ...objectReducerGenerator<UserState>(),
  },
  extraReducers: (builder) => {
    builder.addCase(storeClear, () => initialState);
  },
});
