import React from "react";
import { AppLayout } from "../../component/organisms/AppLayout";
import { Card } from "../../component/atoms/Card";
import { client } from "../../utilities/Apollo";
import { ApolloError, FetchResult } from "@apollo/client";
import { useParams } from "react-router";
import { colors, translations } from "../../utilities/variables";
import {
  CircularProgress,
  OutlinedInputProps,
  TextFieldProps,
} from "@material-ui/core";
import { Brand, Category, Image, Post } from "../../types/Post";
import { styled } from "@mui/material/styles";
import { Icon } from "../../component/atoms/Icon";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import GET_FORM_DATA from "../../query/Post/getFormData";
import { PageTitle } from "../../component/atoms/PageTitle";
import { FormTitleSubtitle } from "../../component/atoms/FormTitleSubtitle";
import { InputField } from "../../component/atoms/InputField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AutocompleteRenderInputParams } from "@material-ui/lab";
import Switch, { SwitchProps } from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button } from "../../component/atoms/Button";
import { CategoryItem } from "../../component/atoms/CategoryItem";
import { Divider } from "../../component/atoms/Divider";
import { useDispatch } from "../../store/hooks";
import Resizer from "react-image-file-resizer";
import { CameraIcon } from "../../component/atoms/CameraIcon";
import { ImagePreview } from "../../component/atoms/ImagePreview";
import GET_EDIT_POST from "../../query/Post/getEditPost";
import UPDATE_POST from "../../query/Post/updatePost";
import { snackbarSlice } from "../../store/slices/snackbarSlice";
import DELETE_POST_IMAGE from "../../query/Post/deletePostImage";

type Props = {};

interface RouteParams {
  uuid: string;
}

interface IFormInput {
  images: [string];
  title: string;
  description: string;
  price: number;
  brand: number;
  city: string;
  zipCode: string;
  address: string;
  askForBids: boolean;
  allowChat: boolean;
  categories: [number];
}

const schema = yup.object().shape({
  title: yup.string().required(translations.required),
  description: yup.string().required(translations.required),
});

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: colors.blue,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.listbox}`]: {
    [`& .${autocompleteClasses.option}`]: {
      fontFamily: "Poppins",
    },
  },
}));

const ImageUploadWrapper = styled("div")(({ theme }) => ({
  minHeight: "140px",
  border: "1px solid " + colors.lightGrey,
  borderRadius: "8px",
  margin: "1.5rem 0",
  display: "flex",
  alignItems: "center",
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledTextField = styled(
  (props: TextFieldProps & AutocompleteRenderInputParams) => (
    //@ts-ignore
    <TextField
      //@ts-ignore
      InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
      {...props}
    />
  )
)(({ theme }) => ({
  width: "100%",
  fontFamily: "Poppins",
  border: "none !important",
  "& .MuiFilledInput-root": {
    fontFamily: "Poppins",

    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: colors.white,
    border: "1px solid " + colors.inputField,
    padding: "9px !important",
    "&:after,&:before": {
      display: "none",
    },
    "&:hover": {
      backgroundColor: colors.white,
    },
    "&.Mui-focused": {
      backgroundColor: colors.white,
    },
    "&.Mui-disabled": {
      backgroundColor: colors.white,
    },
  },
  "& .MuiInputLabel-root": {
    color: colors.grey,
    fontFamily: "Poppins",
    "&.Mui-focused": {
      color: colors.grey,
    },
  },
}));

type SelectedCategories = [number] | [];
type ExistingImages = [string] | [];

export const EditPostItem: React.ComponentType<Props> = () => {
  const params = useParams<RouteParams>();
  const [loadingData, setLoadingData] = React.useState(true);
  const [categories, setCategories] = React.useState<[Category] | []>([]);
  const [brands, setBrands] = React.useState<[Brand] | []>([]);
  const [categoryError, setCategoryError] = React.useState(false);
  const [images, setImages] = React.useState<[string] | []>([]);
  const [post, setPost] = React.useState<Post | null>(null);
  const [existingImages, setExistingImages] = React.useState<Image[] | []>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<
    [number] | []
  >([]);
  const [error, setError] = React.useState<string>("");
  const dispatch = useDispatch();

  const getFormData = React.useCallback(async () => {
    setLoadingData(true);
    await client
      .query({ query: GET_FORM_DATA })
      .then((response) => onDataSuccess(response))
      .catch((error) => onDataError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = React.useState(false);

  const onDataSuccess = (response: FetchResult) => {
    setCategories(response?.data?.getCategories);
    setBrands(response?.data?.getBrands);
    getPost();
  };

  const onPostSuccess = (response: FetchResult) => {
    const post = response?.data?.getEditPost as Post;
    if (!post) {
      setLoadingData(false);
      setError("Sorry, this is not possible");
      return;
    }
    setPost(post);
    setValue("title", post.title);
    setValue("description", post.description);
    setValue("address", post.address);
    setValue("city", post.city);
    setValue("zipCode", post.zip);
    if (post.brand) {
      setValue("brand", post.brand.id);
    }
    setValue("price", post.price);
    setValue("askForBids", post.askForBids);
    setValue("allowChat", post.allowChat);
    const selectedCategories = [] as SelectedCategories;
    post.categories.forEach((category) => {
      selectedCategories.push(category.id as never);
    });
    setSelectedCategories(selectedCategories as [number]);

    const existingImages = [] as ExistingImages;
    post.images.forEach((image) => {
      existingImages.push(image.url as never);
    });
    setImages(existingImages);
    setExistingImages(post.images);
    setLoadingData(false);
  };

  const onDataError = (error: ApolloError) => {
    console.log(error);
  };

  React.useEffect(() => {
    async function fetchData() {
      await getFormData();
    }
    fetchData();
  }, [getFormData]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    control,
    setValue,
  } = useForm<IFormInput>({
    defaultValues: { askForBids: true, allowChat: true },
    resolver: yupResolver(schema),
  });

  const getPost = React.useCallback(async () => {
    setLoadingData(true);
    await client
      .query({ query: GET_EDIT_POST, variables: { uuid: params.uuid } })
      .then((response) => onPostSuccess(response))
      .catch((error) => onDataError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    register("title", { required: true });
    register("description", { required: true });
    register("city");
    register("address");
    register("zipCode");
    register("brand");
    register("price");
    register("askForBids");
    register("allowChat");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    await updatePost(data, "public");
  };

  const handleCategoryClick = (id: number) => {
    if (selectedCategories.includes(id as never)) {
      const newSelectedCategories = selectedCategories.filter(
        (f) => f !== id
      ) as [number];
      setSelectedCategories(newSelectedCategories);
    } else {
      const newSelectedCategories = [...selectedCategories, id] as [number];

      setSelectedCategories(newSelectedCategories);
    }
  };

  const updatePost = async (data: any, status: string) => {
    setCategoryError(false);
    trigger();
    if (selectedCategories.length === 0) {
      setCategoryError(true);
      return;
    }
    setLoading(true);

    const newImages = [] as ExistingImages;
    images.forEach((image) => {
      if (!image.startsWith("http")) {
        newImages.push(image as never);
      }
    });

    await client
      .mutate({
        mutation: UPDATE_POST,
        variables: {
          ...data,
          uuid: params.uuid,
          zip: data.zipCode,
          categories: JSON.stringify(selectedCategories),
          brandId: data.brand ? data.brand.id ?? data.brand : null,
          images: JSON.stringify(newImages),
          status: status,
          price: data.price ? data.price.toString() : "",
        },
      })
      .then((response) => onUpdatePostSuccess(response))
      .catch((error) => onUpdatePostError(error));
  };

  const onUpdatePostSuccess = (response: FetchResult) => {
    setLoading(false);
    dispatch(
      snackbarSlice.actions.put({
        open: true,
        message: "Annoncen er opdateret",
        severity: "success",
      })
    );
  };

  const onUpdatePostError = (error: ApolloError) => {
    setLoading(false);
    console.log(error);
  };

  const fileChangedHandler = (event: any) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          710,
          400,
          "JPEG",
          100,
          0,
          (uri) => {
            const newImages = [...images, uri] as [string];
            setImages(newImages);
          },
          "base64"
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onRemove = async (index: number, image?: string) => {
    if (image?.startsWith("http")) {
      const foundImage = existingImages.find((i) => i.url === image);
      await client.mutate({
        mutation: DELETE_POST_IMAGE,
        variables: {
          id: foundImage?.id,
          uuid: params.uuid,
        },
      });
    }
    const newImages = images.filter(
      (f, imageIndex) => index !== imageIndex
    ) as [string];
    setImages(newImages);
  };

  return (
    <AppLayout>
      <PageTitle title="Redigér annonce" />
      <Card
        width={"w-full"}
        padding={2}
        className={"mt-5"}
        rounded={"rounded-lg"}
      >
        {loadingData ? (
          <div className={"text-center pt-8"}>
            <p>Vent venligst, data indlæses…</p>
            <div className={"text-primary-green mt-2"}>
              <CircularProgress color="inherit" />
            </div>
          </div>
        ) : error !== "" ? (
          <div>{error}</div>
        ) : (
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormTitleSubtitle
                title={"Billeder"}
                subtitle={"Max 10 billeder"}
              />
              <ImageUploadWrapper>
                <div className="flex items-center h-full">
                  <div>
                    <label>
                      <CameraIcon />
                      <input
                        type="file"
                        onChange={fileChangedHandler}
                        className="hidden"
                      />
                    </label>
                  </div>
                  {images.map((image, index) => {
                    return (
                      <ImagePreview
                        preview={image}
                        key={index}
                        index={index}
                        onRemove={() => onRemove(index, image)}
                      />
                    );
                  })}
                </div>
              </ImageUploadWrapper>
              <FormTitleSubtitle
                title={"Titel & Beskrivelse"}
                subtitle={
                  "Tilføj en kort titel til listevisning og en god, detaljeret beskrivelse."
                }
              />
              <InputField
                label={"Titel"}
                defaultValue={post?.title}
                name="title"
                error={errors.title?.message}
                onChange={async (e: any, data: any) => {
                  setValue(e.target.name, e.target.value);
                }}
              />
              <InputField
                label={"Beskrivelse"}
                defaultValue={post?.description}
                name="description"
                multiline={true}
                rows={5}
                error={errors.description?.message}
                onChange={async (e: any, data: any) => {
                  setValue(e.target.name, e.target.value);
                }}
              />
              <div className="grid sm:grid-cols-2 grid-cols-1 gap-8 my-3">
                <div>
                  <FormTitleSubtitle title={"Pris"} />
                  <InputField
                    label={"Pris (DKK)"}
                    defaultValue={post?.price}
                    name="price"
                    type={"number"}
                    error={errors.price?.message}
                    onChange={async (e: any, data: any) => {
                      setValue(e.target.name, e.target.value);
                    }}
                  />
                </div>
                <div>
                  <FormTitleSubtitle title={"Mærke"} />
                  <Controller
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={brands}
                        onChange={async (e: any, data: any) => {
                          onChange(data);
                        }}
                        defaultValue={post?.brand}
                        popupIcon={<Icon icon={"chevron-down"} />}
                        getOptionLabel={(option) => option.title}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        PopperComponent={PopperComponent}
                        renderInput={(params) => (
                          <StyledTextField
                            {...params}
                            name={"brand"}
                            variant="filled"
                            placeholder="Vælg mærke"
                          />
                        )}
                      />
                    )}
                    name={"brand"}
                    control={control}
                  />
                </div>
              </div>
              <FormTitleSubtitle
                title={"Lokation"}
                subtitle={"Hvor findes varen?"}
              />
              <div className="grid sm:grid-cols-2 grid-cols-1 gap-8">
                <InputField
                  label={"By"}
                  defaultValue={post?.city}
                  name="city"
                  error={errors.city?.message}
                  onChange={async (e: any, data: any) => {
                    setValue(e.target.name, e.target.value);
                  }}
                />
                <InputField
                  label={"Postnummer"}
                  defaultValue={post?.zip}
                  name="zipCode"
                  error={errors.zipCode?.message}
                  onChange={async (e: any, data: any) => {
                    setValue(e.target.name, e.target.value);
                  }}
                />
              </div>
              <InputField
                label={"Adresse"}
                defaultValue={post?.address}
                name="address"
                error={errors.address?.message}
                onChange={async (e: any, data: any) => {
                  setValue(e.target.name, e.target.value);
                }}
              />
              <div className="my-3">
                <FormTitleSubtitle title={"Præferencer"} />
                <div className="flex flex-wrap">
                  <div className="sm:w-1/3 w-full">
                    <Controller
                      name="askForBids"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          label="Bud accepteres"
                          control={
                            <IOSSwitch
                              sx={{ m: 2 }}
                              onChange={onChange}
                              checked={value}
                            />
                          }
                        />
                      )}
                    />
                  </div>
                  <div className="sm:w-1/3 w-full">
                    <Controller
                      name="allowChat"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          label="Tillad chat"
                          control={
                            <IOSSwitch
                              sx={{ m: 2 }}
                              onChange={onChange}
                              checked={value}
                            />
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <FormTitleSubtitle
                title={"Kategori"}
                subtitle={"Vælg kategori(er) som varen hører til"}
              />
              <div className="flex flex-wrap gap-3 mt-4 mb-6">
                {categories.map((category) => {
                  return (
                    <CategoryItem
                      isSelected={selectedCategories.includes(
                        category.id as never
                      )}
                      key={category.id}
                      title={category.title}
                      id={category.id}
                      onClick={handleCategoryClick}
                    />
                  );
                })}
              </div>
              {categoryError && (
                <div className="text-red-400">{translations.required}</div>
              )}
              <Divider />
              <div className="flex flex-wrap gap-5">
                <div className="sm:w-1/3 w-full">
                  <Button
                    primary={true}
                    title="Opdatér annonce"
                    loading={loading}
                    type={"submit"}
                    onClick={() => {}}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </Card>
    </AppLayout>
  );
};
