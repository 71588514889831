import { ApolloError, FetchResult } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React from "react";
import { PostItemCard } from "../../component/molecules/PostItemCard";
import { AppLayout } from "../../component/organisms/AppLayout";
import GET_POSTS from "../../query/Post/getPosts";
import { Post, Brand, Category } from "../../types/Post";
import { client } from "../../utilities/Apollo";
import styled from "styled-components";
import { colors } from "../../utilities/variables";
import { SearchInput } from "../../component/atoms/SearchInput";
import SEARCH_POSTS from "../../query/Post/searchPosts";
import LocationSearchIcon from "../../assets/icons/icon-location-search.png";
import { Icon } from "../../component/atoms/Icon";
import GET_FILTERS from "../../query/Post/getFilters";
import { FilterCategories } from "../../component/molecules/FilterCategories";
import { FilterRegions } from "../../component/molecules/FilterRegions";
import { FilterBrands } from "../../component/molecules/FilterBrands";
import { useSelector } from "../../store/hooks";
import GET_ANONYMOUS_POSTS from "../../query/Post/getAnonymousPosts";
import SEARCH_ANONYMOUS_POSTS from "../../query/Post/searchAnonymousPosts";
import { Customer } from "../../types/Customer";

const BannerIcon = styled.div`
  width: 62px;
  height: 62px;
  border-radius: 50%;
  outline: 10px solid rgba(255, 255, 255, 0.25);
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {};
export const Dashboard: React.ComponentType<Props> = () => {
  const { user } = useSelector((state) => state.user);
  const [loadingData, setLoadingData] = React.useState(true);
  const [loadingFilters, setLoadingFilters] = React.useState(true);
  const [brands, setBrands] = React.useState<[Brand] | []>([]);
  const [categories, setCategories] = React.useState<[Category] | []>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<
    number[] | []
  >([]);
  const [selectedRegions, setSelectedRegions] = React.useState<[string] | []>(
    []
  );
  const [selectedBrands, setSelectedBrands] = React.useState<[number] | []>([]);
  const [data, setData] = React.useState<Post[] | []>([]);
  const [hasSearchResult, setHasSearchResult] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>("");
  const [favorites, setFavorites] = React.useState<[number] | []>([]);
  const [showBanner, setShowBanner] = React.useState<boolean>(true);
  const getAllPosts = React.useCallback(async () => {
    setLoadingData(true);
    if (user.id) {
      await client
        .query({ query: GET_POSTS })
        .then((response) => onDataSuccess(response))
        .catch((error) => onDataError(error));
    } else {
      await client
        .query({ query: GET_ANONYMOUS_POSTS })
        .then((response) => onAnonymousDataSuccess(response))
        .catch((error) => onDataError(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilters = React.useCallback(async () => {
    setLoadingFilters(true);
    await client
      .query({ query: GET_FILTERS })
      .then((response) => onFiltersSuccess(response))
      .catch((error) => onDataError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAnonymousDataSuccess = (response: FetchResult) => {
    setData(response?.data?.getAnonymousPosts);
    setSearchText("");
    setHasSearchResult(false);
    setLoadingData(false);
  };

  const onDataSuccess = (response: FetchResult) => {
    setData(response?.data?.getPosts.posts);
    setFavorites(response?.data?.getPosts.favorites);
    setSearchText("");
    setHasSearchResult(false);
    setLoadingData(false);
  };

  const onFiltersSuccess = (response: FetchResult) => {
    setBrands(response?.data?.getFilters.brands);
    setCategories(response?.data?.getFilters.categories);
    setLoadingFilters(false);
  };

  const onDataError = (error: ApolloError) => {
    console.log(error);
  };

  React.useEffect(() => {
    async function fetchData() {
      await getAllPosts();
      await getFilters();
    }
    fetchData();
    const showBanner = localStorage.getItem("showBanner");
    if (!showBanner) {
      setShowBanner(true);
    } else {
      let parsedShowBanner = showBanner === "true";
      setShowBanner(parsedShowBanner);
    }
  }, [getAllPosts, getFilters]);

  const afterRating = (type: boolean, id: number) => {
    if (type) {
      const newFavorites = [...favorites, id] as [number];
      setFavorites(newFavorites);
    } else {
      const newFavorites = favorites.filter((f) => f !== id) as [number];
      setFavorites(newFavorites);
    }
  };

  const searchPosts = React.useCallback(async (text: string) => {
    setLoadingData(true);
    setSearchText(text);
    if (user.id) {
      await client
        .query({
          query: SEARCH_POSTS,
          variables: { text: text },
        })
        .then((response) => onSearchSuccess(response))
        .catch((error) => onDataError(error));
    } else {
      await client
        .query({
          query: SEARCH_ANONYMOUS_POSTS,
          variables: { text: text },
        })
        .then((response) => onSearchAnonymousSuccess(response))
        .catch((error) => onDataError(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filterPosts = React.useCallback(
    async (
      user: Customer,
      categories?: number[],
      regions?: string[],
      brands?: number[]
    ) => {
      console.log(categories);
      console.log(regions);
      console.log(brands);
      console.log(
        categories?.length === 0 &&
          regions?.length === 0 &&
          brands?.length === 0
      );
      if (
        categories?.length === 0 &&
        regions?.length === 0 &&
        brands?.length === 0
      ) {
        return await getAllPosts();
      }
      setLoadingData(true);
      if (user.id) {
        await client
          .query({
            query: SEARCH_POSTS,
            variables: {
              categories: categories,
              regions: regions,
              brands: brands,
            },
          })
          .then((response) => onFilterSuccess(response))
          .catch((error) => onDataError(error));
      } else {
        await client
          .query({
            query: SEARCH_ANONYMOUS_POSTS,
            variables: {
              categories: categories,
              regions: regions,
              brands: brands,
            },
          })
          .then((response) => onAnonymousFilterSuccess(response))
          .catch((error) => onDataError(error));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSearchAnonymousSuccess = (response: FetchResult) => {
    setHasSearchResult(true);
    setData(response?.data?.searchAnonymousPosts);
    setLoadingData(false);
  };

  const onSearchSuccess = (response: FetchResult) => {
    setHasSearchResult(true);
    setData(response?.data?.searchPosts.posts);
    setFavorites(response?.data?.searchPosts.favorites);
    setLoadingData(false);
  };

  const onFilterSuccess = (response: FetchResult) => {
    setData(response?.data?.searchPosts.posts);
    setFavorites(response?.data?.searchPosts.favorites);
    setLoadingData(false);
  };

  const onAnonymousFilterSuccess = (response: FetchResult) => {
    setData(response?.data?.searchAnonymousPosts);
    setLoadingData(false);
  };

  const hideBanner = () => {
    localStorage.setItem("showBanner", "false");
    setShowBanner(false);
  };

  const filterByCategories = async (categories: number[]) => {
    setSelectedCategories(categories);
    await filterPosts(
      user,
      categories,
      selectedRegions as [string],
      selectedBrands as [number]
    );
  };

  const clearFilterCategory = async () => {
    setSelectedCategories([]);
    await filterPosts(
      user,
      [],
      selectedRegions as [string],
      selectedBrands as [number]
    );
  };

  const filterByRegions = async (regions: [string]) => {
    setSelectedRegions(regions);
    await filterPosts(
      user,
      selectedCategories as [number],
      regions,
      selectedBrands as [number]
    );
  };

  const clearFilterRegion = async () => {
    setSelectedRegions([]);
    await filterPosts(
      user,
      selectedCategories as [number],
      undefined,
      selectedBrands as [number]
    );
  };

  const filterByBrands = async (brands: [number]) => {
    setSelectedBrands(brands);
    await filterPosts(
      user,
      selectedCategories as [number],
      selectedRegions as [string],
      brands
    );
  };

  const clearFilterBrand = async () => {
    setSelectedBrands([]);
    await filterPosts(
      user,
      selectedCategories as [number],
      selectedRegions as [string],
      undefined
    );
  };

  return (
    <AppLayout>
      <div className="flex justify-between lg:flex-row flex-col">
        <div className="sm:mb-0 mb-2">
          {!hasSearchResult && (
            <div>
              {loadingFilters && (
                <div className={"text-primary-green"}>
                  <CircularProgress color="inherit" />
                </div>
              )}
              {!loadingFilters && (
                <div className="flex flex-wrap lg:flex-row flex-col items-center">
                  <div className="mr-2 lg:block hidden">Filtrér:</div>
                  <div className="flex flex-wrap gap-1">
                    <FilterCategories
                      clear={clearFilterCategory}
                      categories={categories}
                      apply={filterByCategories}
                      count={selectedCategories.length}
                      parentSelectedCategories={selectedCategories}
                    />
                    <FilterRegions
                      clear={clearFilterRegion}
                      apply={filterByRegions}
                      count={selectedRegions.length}
                    />
                    <FilterBrands
                      clear={clearFilterBrand}
                      brands={brands}
                      apply={filterByBrands}
                      count={selectedBrands.length}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <SearchInput
          label={"Søg"}
          onSubmitForm={searchPosts}
          resetSearchForm={getAllPosts}
        />
      </div>

      {hasSearchResult && (
        <div>
          <h2 className="font-semibold text-4xl">
            Annoncer for "{searchText}"
          </h2>
          <p className="text-sm">
            {data.length} annoncer{data.length === 1 ? "" : "er"} fundet
          </p>
        </div>
      )}

      {showBanner && (
        <div className="bg-primary-green my-3 p-6 rounded-lg flex items-center justify-between relative">
          <div className="text-white">
            <h2 className="font-semibold text-xl">
              Find de bedste deals fra kollegaer i autobranchen
            </h2>
            <p className="text-sm">Engroshandel kun for autobranchen</p>
          </div>
          <div>
            <BannerIcon>
              <img src={LocationSearchIcon} alt={"location search"} />
            </BannerIcon>
          </div>
          <div
            className="absolute top-1 right-1 cursor-pointer"
            onClick={hideBanner}
          >
            <Icon icon={"close"} color={"text-white"} />
          </div>
        </div>
      )}

      {loadingData ? (
        <div className={"text-center pt-8"}>
          <p>Vent venligst, data indlæses…</p>
          <div className={"text-primary-green mt-2"}>
            <CircularProgress color="inherit" />
          </div>
        </div>
      ) : (
        <div
          className={
            "grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-7 mt-5"
          }
        >
          {data?.map((post) => {
            if (post.type === "category") {
              return (
                <article
                  key={post.id}
                  onClick={() =>
                    filterByCategories(post.categories.map((c) => c.id))
                  }
                  className="flex font-medium text-md items-center h-40 overflow-hidden rounded-lg bg-primary-green justify-center cursor-pointer"
                >
                  {post.title}
                </article>
              );
            }
            return (
              <PostItemCard
                post={post}
                key={post.id}
                favorites={favorites}
                afterRating={afterRating}
              />
            );
          })}
        </div>
      )}
      {!loadingData && data?.length === 0 && (
        <div>Ingen annoncer matcher de valgte filtre</div>
      )}
    </AppLayout>
  );
};
