import styled from "styled-components";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink } from "react-router-dom";
import { colors } from "../../utilities/variables";

export interface MenuLinkProps {
  label: string;
  link: string;
}

const StyledListItemLink = styled(NavLink)`
  display: flex;
  height: 64px;
  min-width: 80px;
  background-color: transparent !important;
  color: ${colors.nearlyBlack};
  align-items: center;
  text-align: center;
`;

export const TopMenuLink: React.FC<MenuLinkProps> = ({ label, link }) => {
  return (
    <StyledListItemLink
      to={link}
      className={"border-t-2 border-white mx-3"}
      activeClassName={"border-primary-green"}
    >
      <ListItemText primary={label} />
    </StyledListItemLink>
  );
};
