import React, { useState } from "react";
import { Card } from "../../component/atoms/Card";
import { TitleWithSubtitle } from "../../component/atoms/TitleWithSubtitle";
import { LoginLayout } from "../../component/organisms/LoginLayout";
import { CvrForm } from "../../component/molecules/CvrForm";
import ProgressStepOne from "../../assets/progress-1.svg";
import ProgressStepTwo from "../../assets/progress-2.svg";
import ProgressStepThree from "../../assets/progress-3.svg";
import { publicClient, client } from "../../utilities/Apollo";
import { ApolloError, FetchResult } from "@apollo/client";
import { ValidCvrCompany } from "../../types/Customer";
import { ContactForm } from "../../component/molecules/ContactForm";
import { PasswordForm } from "../../component/molecules/PasswordForm";
import Alert from "@material-ui/lab/Alert";
import GET_CURRENT_USER from "../../query/User/getCurrent";
import { useDispatch } from "../../store/hooks";
import { userSlice } from "../../store/slices/userSlice";
import { useHistory } from "react-router-dom";
import CREATE_CUSTOMER from "../../query/User/createCustomer";
import { AvatarIcon } from "../../component/atoms/AvatarIcon";
import { CheckItem } from "../../component/atoms/CheckItem";
import { OutlinedButton } from "../../component/atoms/OutlinedButton";
import { backdropSlice } from "../../store/slices/backdrop";
import CREATE_MEMBERSHIP from "../../query/User/createMembership";

type Props = {};

interface Form {
  cvr?: string;
  email?: string;
}
//40581154
export const Signup: React.ComponentType<Props> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [step, setStep] = useState<number>(1);
  const [validDataCompany, setValidDataCompany] =
    useState<ValidCvrCompany | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleBackdrop = (state: boolean) => {
    dispatch(
      backdropSlice.actions.put({
        open: state,
      })
    );
  };

  const [form, setForm] = useState<Form>();
  const prepareContactForm = (data: ValidCvrCompany, cvr: string) => {
    setValidDataCompany(data);
    setStep(2);
    setForm({ ...form, cvr });
  };

  const preparePasswordForm = (data: any) => {
    setForm({ ...form, ...data });
    setStep(3);
  };

  const createCustomer = async (password: string) => {
    setLoading(true);

    await publicClient
      .mutate({
        mutation: CREATE_CUSTOMER,
        variables: {
          ...form,
          password,
        },
      })
      .then((response) => onSuccess(response))
      .catch((error) => onError(error));
  };

  const onSuccess = async (response: FetchResult) => {
    let token = response?.data?.createCustomer;
    if (token) {
      localStorage.setItem("token", token);
    }
    setStep(4);
  };

  const onError = (error: ApolloError) => {
    setLoading(false);
    setError(error.graphQLErrors[0].message);
  };

  const getCurrentUser = async () => {
    await client
      .query({ query: GET_CURRENT_USER })
      .then((response) => onGetCurrentUserSuccess(response))
      .catch(() => onGetCurrentError());
  };

  const onGetCurrentUserSuccess = async (response: FetchResult) => {
    setLoading(false);
    dispatch(
      userSlice.actions.put({
        initialized: true,
        user: response?.data?.currentCustomer,
      })
    );

    history.push("/listings");
  };

  const onGetCurrentError = () => {
    setLoading(false);
  };

  const createMembership = async (type: string) => {
    handleBackdrop(true);

    await client
      .mutate({
        mutation: CREATE_MEMBERSHIP,
        variables: {
          type: type,
        },
      })
      .then((response) => onCreateMembershipSuccess(response))
      .catch((error) => onCreateMembershipError(error));
  };

  const onCreateMembershipError = (error: ApolloError) => {
    console.log(error);
    handleBackdrop(false);
  };

  const onCreateMembershipSuccess = async (response: FetchResult) => {
    handleBackdrop(false);
    await getCurrentUser();
  };

  const getTitleBasedOnStep = () => {
    switch (step) {
      case 3:
        return "Password";
      case 2:
        return "Kontakt";
      case 1:
      default:
        return "Konto";
    }
  };

  const getProgressBasedOnStep = () => {
    switch (step) {
      case 3:
        return ProgressStepThree;
      case 2:
        return ProgressStepTwo;
      case 1:
      default:
        return ProgressStepOne;
    }
  };

  const renderFormBasedOnStep = () => {
    switch (step) {
      case 3:
        return (
          <PasswordForm afterFormSubmit={createCustomer} loading={loading} />
        );
      case 2:
        return (
          <ContactForm
            validCvrCompany={validDataCompany}
            afterFormSubmit={preparePasswordForm}
          />
        );
      case 1:
      default:
        return <CvrForm afterFormSubmit={prepareContactForm} />;
    }
  };

  return (
    <LoginLayout>
      {step < 4 && (
        <Card>
          <div className="flex justify-between items-start">
            <div>
              <TitleWithSubtitle
                title={getTitleBasedOnStep()}
                subtitle={
                  "Tast dit virksomhed CVR nummer så henter vi oplysninger"
                }
              />
            </div>

            <img src={getProgressBasedOnStep()} alt="Progress" />
          </div>
          {error && (
            <Alert severity="error" className={"my-3"}>
              {error}
            </Alert>
          )}

          {renderFormBasedOnStep()}
        </Card>
      )}
      {step === 4 && (
        <Card>
          <div className={"text-center"}>
            <div className={"flex justify-around"}>
              <AvatarIcon />
            </div>
            <h2 className={"text-xl font-semibold my-4"}>
              Din konto er oprettet
            </h2>
            <p className={"text-lg mb-5"}>
              Gratis prøve på premium i hele 2023
            </p>
            <div className={"flex justify-around"}>
              <div>
                <CheckItem title={"Se annonce overskrifter"} />
                <CheckItem title={"Se annonce detaljer"} />
                <CheckItem title={"Opret annoncer"} />
                <CheckItem title={"Kontakt sælger"} />
                <CheckItem title={"Se service katalog"} />
              </div>
            </div>
            <div className={"flex justify-around"}>
              <div className="w-1/2">
                <OutlinedButton
                  title={"Start gratis prøve i hele 2023"}
                  color="text-primary-green"
                  onClick={() => createMembership("trial")}
                />
              </div>
            </div>
            <div className={"text-center mt-4"}>
              <div
                className={
                  "font-medium text-base text-primary-green cursor-pointer"
                }
                onClick={() => createMembership("free")}
              >
                Fortsæt med basis
              </div>
            </div>
          </div>
        </Card>
      )}
    </LoginLayout>
  );
};
