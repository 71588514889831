import { gql } from "@apollo/client";

const ACTIVATE_COWORKER = gql`
  mutation activateCoworker(
    $token: String!
    $password: String!
    $name: String!
  ) {
    activateCoworker(input: { token: $token, password: $password, name: $name })
  }
`;

export default ACTIVATE_COWORKER;
