import React from "react";
import { translations } from "../../utilities/variables";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputField } from "../atoms/InputField";
import { Button } from "../atoms/Button";
import Alert, { Color } from "@material-ui/lab/Alert";
import { client } from "../../utilities/Apollo";
import { ApolloError, FetchResult } from "@apollo/client";
import UPDATE_CUSTOMER_PASSWORD from "../../query/User/updateCustomerPassword";

type Props = {};

interface IFormInput {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

const schema = yup.object().shape({
  currentPassword: yup.string().required(translations.required),
  password: yup.string().required(translations.required),
  passwordConfirmation: yup
    .string()
    .required(translations.required)
    .oneOf([yup.ref("password"), null], "Passwords skal være ens"),
});

export const ChangePasswordForm: React.ComponentType<Props> = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>("");
  const [severity, setSeverity] = React.useState<Color>("error");

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
    reset,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setLoading(true);
    trigger();
    reset();
    await updatePassword(data);
  };

  React.useEffect(() => {
    register("currentPassword", { required: true });
    register("password", { required: true });
    register("passwordConfirmation");
  }, [register]);

  const updatePassword = async (data: any) => {
    setLoading(true);
    setError(null);
    await client
      .mutate({
        mutation: UPDATE_CUSTOMER_PASSWORD,
        variables: {
          oldPassword: data.currentPassword,
          newPassword: data.password,
        },
      })
      .then((response) => onSuccess(response))
      .catch((error) => onError(error));
  };

  const onSuccess = async (response: FetchResult) => {
    setLoading(false);
    setError("Password changed successfully");
    setSeverity("success");
    const form = document.getElementById(
      "updateCustomerPassword"
    ) as HTMLFormElement;
    form.reset();
    reset();
    setValue("password", "");
  };

  const onError = (error: ApolloError) => {
    setLoading(false);
    setSeverity("error");
    setError(error.graphQLErrors[0].message);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id={"updateCustomerPassword"}
      className={"flex flex-col"}
    >
      {error && (
        <Alert severity={severity} className={"my-3"}>
          {error}
        </Alert>
      )}
      <InputField
        label={"Nuværende kodeord"}
        defaultValue=""
        type={"password"}
        name="currentPassword"
        error={errors.currentPassword?.message}
        onChange={async (e: any, data: any) => {
          setValue(e.target.name, e.target.value);
        }}
      />
      <InputField
        label={"Nyt kodeord"}
        defaultValue=""
        name="password"
        type={"password"}
        error={errors.password?.message}
        onChange={async (e: any, data: any) => {
          setValue(e.target.name, e.target.value);
        }}
      />

      <InputField
        label={"Gentag nyt kodeord"}
        defaultValue=""
        type={"password"}
        name="passwordConfirmation"
        error={errors.passwordConfirmation?.message}
        onChange={async (e: any, data: any) => {
          setValue(e.target.name, e.target.value);
        }}
      />

      <div className="sm:w-1/3 w-full">
        <Button
          onClick={handleSubmit(onSubmit)}
          loading={loading}
          title={"Opdatér kodekord"}
          primary={true}
        />
      </div>
    </form>
  );
};
