import { CircularProgress, Container } from "@material-ui/core";
import React from "react";
import { AppLayout } from "../../component/organisms/AppLayout";
import { useSelector } from "../../store/hooks";
import { ApolloError, FetchResult } from "@apollo/client";
import { client } from "../../utilities/Apollo";
import { ProfileHeader } from "../../component/molecules/ProfileHeader";
import { ProfileMenu } from "../../component/molecules/ProfileMenu";
import { Post } from "../../types/Post";
import GET_FAVORITE_POSTS from "../../query/User/getFavoritePosts";
import { PostItemCard } from "../../component/molecules/PostItemCard";

type Props = {};

export const Favorites: React.ComponentType<Props> = () => {
  const { user } = useSelector((state) => state.user);

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<[Post] | []>([]);

  const getFavorites = React.useCallback(async () => {
    await client
      .query({ query: GET_FAVORITE_POSTS })
      .then((response) => onDataSuccess(response))
      .catch((error) => onDataError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDataSuccess = (response: FetchResult) => {
    setData(response?.data?.getFavorites);
    setLoading(false);
  };

  const onDataError = (error: ApolloError) => {
    console.log(error);
  };

  React.useEffect(() => {
    async function fetchData() {
      await getFavorites();
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFavorites]);

  const afterRating = () => {
    setLoading(true);
    getFavorites();
  };

  return (
    <AppLayout fluid={true}>
      <ProfileHeader user={user} />

      <Container maxWidth={"lg"} className={"pb-5"}>
        <div className={"mt-5"}>
          <ProfileMenu user={user} />
          {loading ? (
            <div className={"text-center pt-8"}>
              <p>Vent venligst, data indlæses…</p>
              <div className={"text-primary-green mt-2"}>
                <CircularProgress color="inherit" />
              </div>
            </div>
          ) : (
            <div className={"grid sm:grid-cols-4 gap-7 mt-5"}>
              {data.map((post) => {
                return (
                  <PostItemCard
                    post={post}
                    key={post.id}
                    favorites={[]}
                    isFavorite={true}
                    afterRating={afterRating}
                  />
                );
              })}
            </div>
          )}
          {!loading && data.length === 0 && (
            <p>Du har ikke gemt nogle favoritter endnu</p>
          )}
        </div>
      </Container>
    </AppLayout>
  );
};
