import React from "react";
import styled from "styled-components";
import { colors } from "../../utilities/variables";
import classNames from "classnames";

type Props = {
  title: string;
  subtitle?: string;
  titleSize?: string;
  fontWeight?: string;
};

const Subtitle = styled.div`
  color: ${colors.grey};
`;

export const FormTitleSubtitle: React.ComponentType<Props> = ({
  title,
  subtitle,
  titleSize = "text-lg",
  fontWeight = "font-medium",
}) => {
  return (
    <>
      <div
        className={classNames("tracking-tight", titleSize, fontWeight, {
          "mb-3": !subtitle,
        })}
      >
        {title}
      </div>
      {subtitle && <Subtitle className={"text-sm mb-3"}>{subtitle}</Subtitle>}
    </>
  );
};
