import React from "react";
import { useDispatch, useSelector } from "../../store/hooks";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { backdropSlice } from "../../store/slices/backdrop";
import { colors } from "../../utilities/variables";

type Props = {};
export const BackdropComponent: React.ComponentType<Props> = () => {
  const { open } = useSelector((state) => state.backdrop);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(
      backdropSlice.actions.put({
        open: false,
      })
    );
  };

  return (
    <Backdrop
      sx={{ color: colors.primary, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
