import React from "react";
import { colors } from "../../utilities/variables";
import styled from "styled-components";
import { Icon } from "./Icon";
import classNames from "classnames";

type Props = {
  clickHandler: () => void;
  hasAnother: boolean;
  position: string;
};

const StyledCarouselArrow = styled((props) => <button {...props} />)`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.$hasAnother ? colors.white : "rgba(255, 255, 255, 0.25)"};
`;

export const CarouselArrow: React.ComponentType<Props> = ({
  clickHandler,
  hasAnother,
  position,
}) => {
  return (
    <div
      className={classNames("absolute top-0 bottom-0 flex items-center z-10", {
        "right-2": position === "right",
        "left-2": position === "left",
      })}
    >
      <StyledCarouselArrow
        $hasAnother={hasAnother}
        position={position}
        onClick={clickHandler}
      >
        <Icon
          icon={position === "right" ? "chevron-right" : "chevron-left"}
          color={"text-black"}
          size={"text-4xl"}
        />
      </StyledCarouselArrow>
    </div>
  );
};
