import React from "react";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { colors } from "../../utilities/variables";

type Props = {
  label: string;
  defaultValue?: any;
  onChange: any;
  name: string;
  error?: string | undefined;
  type?: "text" | "password" | "email" | "number";
  width?: string;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  radius?: number;
  noErrorString?: string;
  value?: string;
};

type ExtraProps = {
  radius: number;
};

const StyledTextField = styled((props: TextFieldProps & ExtraProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme, radius }) => ({
  width: "100%",
  fontFamily: "Poppins",
  "& .MuiFilledInput-root": {
    fontFamily: "Poppins",
    border: "none",
    overflow: "hidden",
    borderRadius: radius,
    backgroundColor: colors.inputField,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: colors.inputField,
    },
    "&.Mui-focused": {
      backgroundColor: colors.inputField,
    },
    "&.Mui-disabled": {
      backgroundColor: colors.inputField,
    },
  },
  "& .MuiInputLabel-root": {
    color: colors.grey,
    fontFamily: "Poppins",
    "&.Mui-focused": {
      color: colors.grey,
    },
  },
}));

export const InputField: React.ComponentType<Props> = ({
  defaultValue,
  label,
  onChange,
  name,
  error,
  value,
  type = "text",
  width = "w-full",
  multiline = false,
  disabled = false,
  rows = 1,
  radius = 4,
  noErrorString = " ",
}) => {
  return (
    <div className={width}>
      <StyledTextField
        radius={radius}
        multiline={multiline}
        disabled={disabled}
        label={label}
        rows={rows}
        name={name}
        type={type}
        value={value}
        error={error ? true : false}
        defaultValue={defaultValue}
        variant="filled"
        onChange={onChange}
        helperText={error || noErrorString}
      />
    </div>
  );
};
