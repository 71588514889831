import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import AppRouter from "./route/Routing";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./index.css";
import "./assets/fonts/iconfont/icons.css";
import { getFBToken } from "./firebaseInit";

export const history = createBrowserHistory();
const theme = createTheme({
  palette: {
    primary: {
      main: "#002641",
    },
    success: {
      main: "#2CC079",
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        <AppRouter />
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

getFBToken();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
