import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "../../store/hooks";
import { snackbarSlice } from "../../store/slices/snackbarSlice";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type Props = {};
export const SnackbarComponent: React.ComponentType<Props> = () => {
  const { open, severity, message } = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(
      snackbarSlice.actions.put({
        open: false,
      })
    );
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};
