import * as React from "react";
import { Customer } from "../../types/Customer";
import styled from "styled-components";
import { colors } from "../../utilities/variables";
import { Icon } from "../atoms/Icon";

type Props = {
  user: Customer;
};

const HeaderWrapper = styled.div`
  height: 100px;
  background-color: ${colors.profileGreen};
`;

const UserImage = styled.img`
  border-radius: 50%;
  outline: 5px solid rgba(255, 255, 255, 0.25);
`;

const UserAvatar = styled.div`
  width: 98px;
  height: 98px;
  border-radius: 50%;
  outline: 5px solid rgba(255, 255, 255, 0.25);
  background-color: ${colors.grey};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CompanyInfoWrapper = styled.div`
  margin-top: -50px;
`;

export const ProfileHeader: React.ComponentType<Props> = ({ user }) => {
  return (
    <>
      <HeaderWrapper></HeaderWrapper>
      <CompanyInfoWrapper className={"text-center flex flex-col items-center"}>
        {user.image && <UserImage src={user.image} alt={user.company} />}
        {!user.image && (
          <UserAvatar>
            <Icon icon={"profile"} color={"text-white"} size={"text-4xl"} />
          </UserAvatar>
        )}
        <h2 className={"mt-2 font-semibold text-2xl"}>{user.company}</h2>
        <p className="text-grey-primary">{user.city}</p>
      </CompanyInfoWrapper>
    </>
  );
};
