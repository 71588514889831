import { ApolloError, FetchResult } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Card } from "../../component/atoms/Card";
import { AppLayout } from "../../component/organisms/AppLayout";
import GET_MESSAGES from "../../query/Message/getMessages";
import GET_THREADS from "../../query/Message/getThreads";
import GET_POST from "../../query/Post/getPost";
import { Message } from "../../types/Message";
import { Post } from "../../types/Post";
import { Thread } from "../../types/Thread";
import { client } from "../../utilities/Apollo";
import { PostChatItem } from "../../component/atoms/PostChatItem";
import { ThreadChatItem } from "../../component/atoms/ThreadChatItem";
import { FabButton } from "../../component/atoms/FabButton";
import { Icon } from "../../component/atoms/Icon";
import { ChatForm } from "../../component/molecules/ChatForm";
import { io } from "socket.io-client";
import { useSelector } from "../../store/hooks";

const socket = io(process.env.REACT_APP_SOCKET_URL || "", {
  reconnectionDelayMax: 10000,
});
type Props = {};
interface RouteParams {
  uuid: string;
}

export const MessagesThread: React.ComponentType<Props> = () => {
  const { user } = useSelector((state) => state.user);

  socket.emit("subscribe", { room: user.id });
  const [loadingData, setLoadingData] = React.useState(true);
  const [loadingMessages, setLoadingMessages] = React.useState(true);
  const [data, setData] = React.useState<Thread[] | []>([]);
  const [messages, setMessages] = React.useState<Message[] | []>([]);
  const location = useLocation();

  const [post, setPost] = React.useState<Post | null>(null);
  const [activeThread, setActiveThread] = React.useState<Thread | null>(null);
  const params = useParams<RouteParams>();
  const threadRef = React.useRef(activeThread);
  React.useEffect(() => {
    threadRef.current = activeThread;
  });

  const history = useHistory();
  const getPost = React.useCallback(async () => {
    await client
      .query({ query: GET_POST, variables: { uuid: params.uuid } })
      .then((response) => onPostSuccess(response))
      .catch((error) => onDataError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getThreadsByPost = React.useCallback(async () => {
    setLoadingData(true);
    await client
      .query({ query: GET_THREADS, variables: { id: params.uuid } })
      .then((response) => onDataSuccess(response))
      .catch((error) => onDataError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPostSuccess = async (response: FetchResult) => {
    setPost(response?.data?.getPost);
    await getThreadsByPost();
  };

  const onDataSuccess = async (response: FetchResult) => {
    setData(response?.data?.getThreads);
    if (response?.data?.getThreads.length) {
      const state = location.state;
      if (typeof state !== "undefined") {
        history.replace({});
        setActiveThread(state.thread);
        await getMessages(state.thread.id);
      } else {
        setActiveThread(response?.data?.getThreads[0]);
        await getMessages(response?.data?.getThreads[0].id);
      }
    }
    setLoadingData(false);
  };

  const onDataError = (error: ApolloError) => {
    console.log(error);
  };

  const getMessages = React.useCallback(async (id) => {
    setLoadingMessages(true);
    await client
      .query({ query: GET_MESSAGES, variables: { threadId: id } })
      .then((response) => onMessagesSuccess(response))
      .catch((error) => onDataError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMessagesSuccess = async (response: FetchResult) => {
    setMessages(response?.data?.getMessages);
    setLoadingMessages(false);
  };

  const changeActiveThread = (thread: Thread) => {
    setActiveThread(thread);
    getMessages(thread.id);
  };

  React.useEffect(() => {
    async function fetchData() {
      await getPost();
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPost]);

  React.useEffect(() => {
    socket.on("new message", (data: any) => {
      if (threadRef.current && threadRef.current.id === data.threadId) {
        setMessages((prevMsg) => [...prevMsg, data] as [Message]);
      }

      setData((prevData) => {
        const newData = prevData.map((thread) => {
          return thread.id === data.threadId
            ? ({
                ...thread,
                lastMessageCreatedAt: data.createdAt,
              } as Thread)
            : thread;
        });
        return newData;
      });
    });
  }, []);

  return (
    <AppLayout>
      <FabButton
        onClick={() => {
          history.push("/messages");
        }}
      >
        <Icon icon={"chevron-left"} size={"text-3xl"} />
      </FabButton>
      <div className={"mt-5"}>
        {data.length > 0 && (
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-5">
            <div className="">
              <Card
                width={"w-full"}
                padding={1}
                className={"mt-5"}
                rounded={"rounded-lg"}
              >
                {loadingData && (
                  <div className={"text-center pt-8"}>
                    <p>Vent venligst, data indlæses…</p>
                    <div className={"text-primary-green mt-2"}>
                      <CircularProgress color="inherit" />
                    </div>
                  </div>
                )}
                {post && (
                  <div>
                    <PostChatItem post={post} />
                    {data.map((thread, index) => {
                      return (
                        <ThreadChatItem
                          thread={thread}
                          key={thread.id}
                          isLast={data.length - 1 === index}
                          isActive={thread.id === activeThread?.id}
                          onClick={(thread) => changeActiveThread(thread)}
                        />
                      );
                    })}
                  </div>
                )}
              </Card>
            </div>
            <div className="sm:col-span-2">
              <Card
                width={"w-full"}
                padding={0}
                className={"mt-5"}
                rounded={"rounded-lg"}
              >
                {loadingMessages && (
                  <div className={"text-center pt-8"}>
                    <p>Vent venligst, data indlæses…</p>
                    <div className={"text-primary-green mt-2"}>
                      <CircularProgress color="inherit" />
                    </div>
                  </div>
                )}
                {!loadingMessages && activeThread && (
                  <ChatForm
                    thread={activeThread}
                    messages={messages}
                    post={post}
                  />
                )}
              </Card>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};
