import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Post } from "../../types/Post";
import DefaultImage from "../../assets/default-engros.png";
import classNames from "classnames";
import { Icon } from "../atoms/Icon";

const CardImage = styled((props) => <div {...props} />)`
  background: url("${(props) => props.background}");
  height: 80px;
  width: 80px;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
`;

export interface Props {
  post: Post;
  index: number;
  isLast: boolean;
  link: string;
}

export const PostItemList: React.FC<Props> = ({
  post,
  index,
  isLast,
  link,
}) => {
  return (
    <article
      className={classNames({
        "py-5": index !== 0,
        "pb-5": index === 0,
        "border-b border-gray-200": !isLast,
      })}
    >
      <Link to={link}>
        <div className={"flex justify-between"}>
          <div className="flex">
            <CardImage
              background={
                post.images.length > 0 ? post.images[0].url : DefaultImage
              }
            />
            <div className="flex flex-col justify-between">
              <header className="flex flex-col leading-tight px-2 md:px-4">
                <h2 className="font-semibold text-primary-black">
                  {post.title}
                </h2>
                <p className="text-primary-black font-medium text-sm">
                  {post.price !== null &&
                    post.price !== 0 &&
                    post.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " kr."}
                </p>
              </header>
              <div className="px-2 md:px-4">
                <p className="text-grey-primary text-sm font-medium"></p>
              </div>
            </div>
          </div>
          <Icon
            icon={"chevron-right"}
            color={"text-grey-primary"}
            size={"text-3xl"}
          />
        </div>
      </Link>
    </article>
  );
};
