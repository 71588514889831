import React from "react";
import { Post } from "../../types/Post";
import styled from "styled-components";
import DefaultImage from "../../assets/default-engros.png";
import classNames from "classnames";
import { ChipComponent } from "./Chip";
import { colors } from "../../utilities/variables";

type Props = {
  post: Post;
  border?: boolean;
};

const CardImage = styled((props) => <div {...props} />)`
  background: url("${(props) => props.background}");
  height: 44px;
  width: 44px;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
`;

export const PostChatItem: React.ComponentType<Props> = ({
  post,
  border = true,
}) => {
  return (
    <div
      className={classNames("flex justify-between items-center", {
        "pb-3 border-b": border,
      })}
    >
      <div className="flex">
        <CardImage
          background={
            post.images.length > 0 ? post.images[0].url : DefaultImage
          }
        />
        <div className="flex flex-col justify-between">
          <header className="flex flex-col leading-tight px-2 md:px-4">
            <h2 className="font-medium text-sm text-primary-black">
              {post.title}
            </h2>
            <p className="text-primary-black font-medium text-sm">
              {post.price !== null &&
                post.price !== 0 &&
                post.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
                  " kr."}
            </p>
          </header>
        </div>
      </div>
      {post?.sold && (
        <ChipComponent
          title={"Solgt"}
          textColor={colors.primary}
          backgroundColor={colors.lightGreen}
        />
      )}
    </div>
  );
};
