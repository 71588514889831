import { gql } from "@apollo/client";

const GET_SERVICE_CATALOG = gql`
  query getServiceCatalog {
    getServiceCatalog {
      id
      company
      address
      description
      email
      phone
      web
      createdAt
    }
  }
`;

export default GET_SERVICE_CATALOG;
