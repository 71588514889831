import { Container } from "@material-ui/core";
import React from "react";
import { Card } from "../../component/atoms/Card";
import { AppLayout } from "../../component/organisms/AppLayout";
import { useSelector } from "../../store/hooks";
import { FormTitleSubtitle } from "../../component/atoms/FormTitleSubtitle";
import { InputField } from "../../component/atoms/InputField";
import { translations } from "../../utilities/variables";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "../../component/atoms/Button";
import { Customer } from "../../types/Customer";
import { ApolloError, FetchResult } from "@apollo/client";
import { client } from "../../utilities/Apollo";
import GET_CURRENT_USER from "../../query/User/getCurrent";
import UPDATE_PROFILE from "../../query/User/updateProfile";
import { useDispatch } from "../../store/hooks";
import { userSlice } from "../../store/slices/userSlice";
import { UploadUserProfileImage } from "../../component/molecules/UploadUserProfileImage";
import { ChangePasswordForm } from "../../component/molecules/ChangePasswordForm";
import { ProfileHeader } from "../../component/molecules/ProfileHeader";
import { ProfileMenu } from "../../component/molecules/ProfileMenu";
import { snackbarSlice } from "../../store/slices/snackbarSlice";

type Props = {};

interface IFormInput {
  email: string;
  company: string;
  address: string;
  name: string;
  city: string;
  phone: string;
  zip: string;
}

const schema = yup.object().shape({
  email: yup.string().required(translations.required).email("Ugyldig email"),
  company: yup.string().required(translations.required),
  name: yup.string().required(translations.required),
  address: yup.string().required(translations.required),
  city: yup.string().required(translations.required),
  phone: yup.string().required(translations.required),
  zip: yup.string().required(translations.required),
});

export const Profile: React.ComponentType<Props> = () => {
  const { user } = useSelector((state) => state.user);

  const [loading, setLoading] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(true);
  const [data, setData] = React.useState<Customer | null>(null);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    trigger();
    setLoading(true);
    await client
      .mutate({ mutation: UPDATE_PROFILE, variables: { ...data } })
      .then((response) => onSuccess(response))
      .catch((error) => onDataError(error));
  };

  const onSuccess = (response: FetchResult) => {
    const returnedUser = response?.data?.updateProfile;
    dispatch(
      userSlice.actions.put({
        initialized: true,
        user: returnedUser,
      })
    );
    dispatch(
      snackbarSlice.actions.put({
        open: true,
        message: "Profilen er opdateret",
        severity: "success",
      })
    );
    setLoading(false);
  };

  React.useEffect(() => {
    register("email", { required: true });
    register("company", { required: true });
    register("address", { required: true });
    register("city", { required: true });
    register("zip", { required: true });
    register("phone", { required: true });
    register("name", { required: true });
  }, [register]);

  const getCustomer = React.useCallback(async () => {
    setLoadingData(true);
    await client
      .query({ query: GET_CURRENT_USER })
      .then((response) => onDataSuccess(response))
      .catch((error) => onDataError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDataSuccess = (response: FetchResult) => {
    setData(response?.data?.currentCustomer);
    Object.keys(response?.data?.currentCustomer).forEach((key) => {
      return setValue(
        key as
          | "email"
          | "name"
          | "company"
          | "phone"
          | "address"
          | "zip"
          | "city",
        response?.data?.currentCustomer[key]
      );
    });
    setLoadingData(false);
  };

  const onDataError = (error: ApolloError) => {
    console.log(error);
  };

  React.useEffect(() => {
    async function fetchData() {
      await getCustomer();
    }
    fetchData();
  }, [getCustomer]);

  return (
    <AppLayout fluid={true}>
      <ProfileHeader user={user} />

      <Container maxWidth={"lg"} className={"pb-5"}>
        <div className={"mt-5"}>
          <ProfileMenu user={user} />
          <Card
            width={"w-full"}
            padding={2}
            className={"mt-5"}
            rounded={"rounded-lg"}
          >
            <div
              className={"grid sm:grid-cols-2 grid-cols-1 gap-20 border-b pb-3"}
            >
              <div>
                <FormTitleSubtitle
                  title={"Profile info"}
                  fontWeight="font-semibold"
                />
                {!loadingData && (
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={"flex flex-col"}
                  >
                    <InputField
                      label={"Virksomhed"}
                      name="company"
                      disabled={data?.ownerId !== null}
                      defaultValue={data?.company}
                      error={errors.company?.message}
                      onChange={async (e: any, data: any) => {
                        setValue(e.target.name, e.target.value);
                      }}
                    />

                    <InputField
                      label={"Brugernavn"}
                      defaultValue={data?.name}
                      name="name"
                      error={errors.name?.message}
                      onChange={async (e: any, data: any) => {
                        setValue(e.target.name, e.target.value);
                      }}
                    />

                    <InputField
                      label={"E-mail"}
                      defaultValue={data?.email}
                      name="email"
                      error={errors.email?.message}
                      onChange={async (e: any, data: any) => {
                        setValue(e.target.name, e.target.value);
                      }}
                    />

                    <InputField
                      label={"Telefon"}
                      defaultValue={data?.phone}
                      name="phone"
                      error={errors.phone?.message}
                      onChange={async (e: any, data: any) => {
                        setValue(e.target.name, e.target.value);
                      }}
                    />

                    <InputField
                      label={"Adresse"}
                      disabled={data?.ownerId !== null}
                      defaultValue={data?.address}
                      name="address"
                      error={errors.address?.message}
                      onChange={async (e: any, data: any) => {
                        setValue(e.target.name, e.target.value);
                      }}
                    />

                    <InputField
                      label={"By"}
                      disabled={data?.ownerId !== null}
                      defaultValue={data?.city}
                      name="city"
                      error={errors.city?.message}
                      onChange={async (e: any, data: any) => {
                        setValue(e.target.name, e.target.value);
                      }}
                    />

                    <InputField
                      label={"Postnummer"}
                      disabled={data?.ownerId !== null}
                      defaultValue={data?.zip}
                      name="zip"
                      error={errors.zip?.message}
                      onChange={async (e: any, data: any) => {
                        setValue(e.target.name, e.target.value);
                      }}
                    />
                  </form>
                )}
              </div>
              <div>
                <FormTitleSubtitle
                  title={"Profilbillede"}
                  fontWeight="font-semibold"
                />
                <UploadUserProfileImage user={user} />
                <div className="mt-8">
                  <FormTitleSubtitle
                    title={"Opdatér kodeord"}
                    fontWeight="font-semibold"
                  />
                  <ChangePasswordForm />
                </div>
              </div>
            </div>
            <div className="sm:w-1/4 w-full pt-3">
              <Button
                onClick={handleSubmit(onSubmit)}
                loading={loading}
                title={"Opdatér"}
                primary={true}
              />
            </div>
          </Card>
        </div>
      </Container>
    </AppLayout>
  );
};
