import { ApolloError, FetchResult } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React from "react";
import { Card } from "../../component/atoms/Card";
import { ChipComponent } from "../../component/atoms/Chip";
import { Icon } from "../../component/atoms/Icon";
import { PageTitle } from "../../component/atoms/PageTitle";
import { AppLayout } from "../../component/organisms/AppLayout";
import GET_SERVICE_CATALOG from "../../query/Post/getServiceCatalog";
import { ServiceCatalogT } from "../../types/Post";
import { client } from "../../utilities/Apollo";
import { colors } from "../../utilities/variables";

type Props = {};
export const ServiceCatalog: React.ComponentType<Props> = () => {
  const [loadingData, setLoadingData] = React.useState(true);
  const [data, setData] = React.useState<ServiceCatalogT[] | []>([]);

  const getServiceCatalog = React.useCallback(async () => {
    setLoadingData(true);
    await client
      .query({ query: GET_SERVICE_CATALOG })
      .then((response) => onDataSuccess(response))
      .catch((error) => onDataError(error));
  }, []);

  const onDataSuccess = (response: FetchResult) => {
    setData(response?.data?.getServiceCatalog);
    setLoadingData(false);
  };

  const onDataError = (error: ApolloError) => {
    console.log(error);
  };

  React.useEffect(() => {
    async function fetchData() {
      await getServiceCatalog();
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getServiceCatalog]);

  return (
    <AppLayout>
      <PageTitle title="Service Katalog" />

      {loadingData ? (
        <div className={"text-center pt-8"}>
          <p>Vent venligst, data indlæses…</p>
          <div className={"text-primary-green mt-2"}>
            <CircularProgress color="inherit" />
          </div>
        </div>
      ) : (
        <div className={"mt-5"}>
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-10">
            {data.map((serviceCatalog, index) => {
              const weekBefore = new Date(Date.now() + 604800000);
              const isNew =
                new Date(serviceCatalog.createdAt).getTime() >
                weekBefore.getTime();
              return (
                <Card
                  key={index}
                  width={"w-full"}
                  padding={2}
                  className={"mt-5"}
                  rounded={"rounded-lg"}
                >
                  <div className="flex justify-between">
                    <h2 className="font-semibold text-xl">
                      {serviceCatalog.company}
                    </h2>
                    {isNew && (
                      <div>
                        <ChipComponent
                          title={"Ny"}
                          textColor={colors.primary}
                          backgroundColor={colors.lightGreen}
                        />
                      </div>
                    )}
                  </div>
                  <div className="text-grey-primary mt-3">
                    {serviceCatalog.description}
                  </div>
                  <div className="mt-3">
                    <div className="flex items-center border-b py-3">
                      <Icon
                        icon={"pin"}
                        color="text-primary-green"
                        size={"text-2xl"}
                      />
                      <span className="ml-3">{serviceCatalog.address}</span>
                    </div>
                    <a
                      href={"tel:" + serviceCatalog.phone}
                      className="flex items-center border-b py-3 cursor-pointer"
                    >
                      <Icon
                        icon={"phone"}
                        color="text-primary-green"
                        size={"text-2xl"}
                      />
                      <span className="ml-3">{serviceCatalog.phone}</span>
                    </a>
                    <a
                      href={"mailto:" + serviceCatalog.email}
                      className="flex items-center border-b py-3 cursor-pointer"
                    >
                      <Icon
                        icon={"chat"}
                        color="text-primary-green"
                        size={"text-2xl"}
                      />
                      <span className="ml-3">{serviceCatalog.email}</span>
                    </a>
                    <a
                      href={serviceCatalog.web}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center border-b py-3 cursor-pointer"
                    >
                      <Icon
                        icon={"link"}
                        color="text-primary-green"
                        size={"text-2xl"}
                      />
                      <span className="ml-3">Besøg hjememside</span>
                    </a>
                  </div>
                </Card>
              );
            })}
          </div>

          {data.length === 0 && (
            <div className="text-center flex flex-col items-center">
              <p className="text-grey-primary mb-3">
                There are no service catalog items
              </p>
            </div>
          )}
        </div>
      )}
    </AppLayout>
  );
};
