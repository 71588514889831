import { createSlice } from "@reduxjs/toolkit";

import { objectReducerGenerator } from "../objectReducerGenerator";

export interface ModalState {
  deleteCustomer?: boolean;
  deleteListing?: boolean;
  createServiceCatalog?: boolean;
  deleteCoworker?: boolean;
  postCreated?: boolean;
  uploadAvatar?: boolean;
  cancelPlan?: boolean;
  chooseMembership?: boolean;
  feedbackForm?: boolean;
  aboutUs?: boolean;
}

const initialState: ModalState = {
  deleteCustomer: false,
  deleteListing: false,
  createServiceCatalog: false,
  deleteCoworker: false,
  postCreated: false,
  uploadAvatar: false,
  cancelPlan: false,
  chooseMembership: false,
  feedbackForm: false,
  aboutUs: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    ...objectReducerGenerator<ModalState>(),
  },
});
