import { gql } from "@apollo/client";

const SET_NEW_PASSWORD = gql`
  mutation setNewPassword($token: String!, $newPassword: String!) {
    setNewPassword: setNewPassword(
      input: { token: $token, newPassword: $newPassword }
    )
  }
`;

export default SET_NEW_PASSWORD;
