import React from "react";
import styled from "styled-components";
import { colors } from "../../utilities/variables";

type Props = {
  title: string;
  id: number;
  isSelected: boolean;
  onClick: (id: number) => void;
};

const Wrapper = styled((props) => <div {...props} />)`
  background-color: ${(props) =>
    props.$isSelected ? colors.black : colors.inputField};
  text-align: center;
  padding: 8px 16px;
  color: ${(props) => (props.$isSelected ? colors.white : colors.black)};
  font-weight: 500;
  font-size: 13px;
  border-radius: 2px;
  display: inline;
  cursor: pointer;
`;

export const CategoryItem: React.ComponentType<Props> = ({
  title,
  isSelected,
  onClick,
  id,
}) => {
  const handleOnClick = () => {
    onClick(id);
  };

  return (
    <Wrapper $isSelected={isSelected} onClick={handleOnClick}>
      {title}
    </Wrapper>
  );
};
