import * as React from "react";
import { Customer } from "../../types/Customer";
import { CustomerDetailLinkButton } from "../atoms/CustomerDetailLinkButton";

type Props = {
  user: Customer;
};

export const ProfileMenu: React.ComponentType<Props> = ({ user }) => {
  return (
    <div className={"my-4 flex gap-5 flex-wrap"}>
      <CustomerDetailLinkButton title={"Profil"} link={"/profile"} />
      {user.activeMembership.type !== "free" && (
        <CustomerDetailLinkButton
          title={"Invitér medarbejder"}
          link={"/invite-coworkers"}
        />
      )}
      <CustomerDetailLinkButton title={"Favoritter"} link={"/favorites"} />
      {!user.ownerId && (
        <CustomerDetailLinkButton title={"Medlemskab"} link={"/membership"} />
      )}
      {user.activeMembership.type !== "free" && (
        <CustomerDetailLinkButton
          title={"Betalingsmetode"}
          link={"/payment-methods"}
        />
      )}
    </div>
  );
};
