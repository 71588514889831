import styled from "styled-components";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { Link, useLocation } from "react-router-dom";
import { colors } from "../../utilities/variables";

export interface MenuLinkProps {
  label: string;
  link: string;
}

const StyledListItemLink = styled(ListItemLink)`
  color: ${(props) => (props.selected ? colors.primary : colors.nearlyBlack)};
  background-color: transparent !important;
`;

function ListItemLink(props: ListItemProps<Link, { button?: true }>) {
  return <ListItem button component={Link} {...props} />;
}

export const MenuLink: React.FC<MenuLinkProps> = ({ label, link }) => {
  const location = useLocation();
  const isSelected = location.pathname.startsWith(link);
  return (
    <StyledListItemLink button to={link} selected={isSelected}>
      <ListItemText primary={label} />
    </StyledListItemLink>
  );
};
