import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { authenticatedPage } from "./authenticatedPage";
import { useHistory } from "react-router";
import { useDispatch } from "../store/hooks";
import { userSlice } from "../store/slices/userSlice";
import { client } from "../utilities/Apollo";
import GET_CURRENT_USER from "../query/User/getCurrent";
import { storeClear } from "../store/actions/storeClear";

import { Login } from "./routes/Login";
import { Dashboard } from "./routes/Dashboard";
import { SnackbarComponent } from "../component/atoms/Snackbar";
import { BackdropComponent } from "../component/atoms/Backdrop";
import { ActivateCoworker } from "./routes/ActivateCoworker";
import { Start } from "./routes/Start";
import { Signup } from "./routes/Signup";
import { ResetPassword } from "./routes/ResetPassword";
import { SetNewPassword } from "./routes/SetNewPassword";
import { ListingDetail } from "./routes/ListingDetail";
import { NewPostItem } from "./routes/NewPostItem";
import { MyItems } from "./routes/MyItems";
import { Messages } from "./routes/Messages";
import { MessagesThread } from "./routes/MessagesThread";
import { MessagesPost } from "./routes/MessagesPost";
import { EditPostItem } from "./routes/EditPostItem";
import { Profile } from "./routes/Profile";
import { InviteCoworkers } from "./routes/InviteCoworkers";
import { Favorites } from "./routes/Favorites";
import { NotFound } from "./routes/NotFound";
import { Membership } from "./routes/Membership";
import { PaymentSuccess } from "./routes/PaymentSuccess";
import { PaymentMethods } from "./routes/PaymentMethods";
import { ServiceCatalog } from "./routes/ServiceCatalog";

const Routing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      if (token) {
        const getUser = async () => {
          await client
            .query({ query: GET_CURRENT_USER })
            .then((response) => {
              dispatch(
                userSlice.actions.put({
                  initialized: true,
                  user: response.data.currentCustomer,
                })
              );
            })
            .catch(() => {
              localStorage.removeItem("token");
              dispatch(storeClear());
              return <Redirect to="/login" />;
            });
        };
        await getUser();
      } else {
        return <Redirect to="/login" />;
      }
    }
    fetchData();
  }, [history, dispatch]);

  return (
    <>
      <CssBaseline />
      <Switch>
        <Redirect exact from="/" to="/listings" />
        <Route exact path="/start" component={Start} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/set-new-password" component={SetNewPassword} />
        <Route exact path="/activate-coworker" component={ActivateCoworker} />

        <Route exact path="/listings" component={Dashboard} />
        <Route exact path="/listings/:uuid" component={ListingDetail} />
        <Route
          exact
          path="/post-item"
          component={authenticatedPage(NewPostItem)}
        />
        <Route exact path="/my-items" component={authenticatedPage(MyItems)} />
        <Route exact path="/messages" component={authenticatedPage(Messages)} />
        <Route
          exact
          path="/messages/thread/:uuid"
          component={authenticatedPage(MessagesThread)}
        />
        <Route
          exact
          path="/messages/post/:uuid"
          component={authenticatedPage(MessagesPost)}
        />
        <Route
          exact
          path="/edit-post-item/:uuid"
          component={authenticatedPage(EditPostItem)}
        />
        <Route exact path="/profile" component={authenticatedPage(Profile)} />
        <Route
          exact
          path="/invite-coworkers"
          component={authenticatedPage(InviteCoworkers)}
        />
        <Route
          exact
          path="/favorites"
          component={authenticatedPage(Favorites)}
        />
        <Route
          exact
          path="/membership"
          component={authenticatedPage(Membership)}
        />
        <Route
          exact
          path="/payment-success"
          component={authenticatedPage(PaymentSuccess)}
        />
        <Route
          exact
          path="/payment-methods"
          component={authenticatedPage(PaymentMethods)}
        />
        <Route
          exact
          path="/service-catalog"
          component={authenticatedPage(ServiceCatalog)}
        />
        <Route component={NotFound} />
      </Switch>
      <SnackbarComponent />
      <BackdropComponent />
    </>
  );
};

export default Routing;
