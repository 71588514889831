import { gql } from "@apollo/client";

const GET_POSTS = gql`
  query {
    getPosts {
      favorites
      posts {
        id
        uuid
        title
        price
        createdAt
        city
        zip
        askForBids
        sold
        type
        categories {
          id
        }
        images {
          url
        }
      }
    }
  }
`;

export default GET_POSTS;
