import React from "react";
import { IconsId } from "../../assets/fonts/iconfont/icons";
import { Icon } from "./Icon";

type Props = {
  icon: IconsId;
  value?: string;
};

export const CompanyDetailItem: React.ComponentType<Props> = ({
  icon,
  value,
}) => {
  return (
    <div className={"flex py-2 items-center"}>
      <Icon icon={icon} color={"text-primary-green"} size={"text-2xl"} />
      <div className={"ml-2"}>{value}</div>
    </div>
  );
};
