import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { client } from "./utilities/Apollo";
import { FetchResult } from "@apollo/client";
import UPDATE_DEVICE_TOKEN from "./query/User/updateDeviceToken";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const publicKey =
  "BMnSaY_oHssJZS8iJYpF1sS8FLpaqn8h6hhDTp4kegmr-12uuBpzYl5wFME20L9_UW6RdFy_30_1JgnGGhFnPXc";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCC38kZYnG-EH_lKbviC4f1BHxGrAeMvnc",
  authDomain: "engrosauto-9f83b.firebaseapp.com",
  projectId: "engrosauto-9f83b",
  storageBucket: "engrosauto-9f83b.appspot.com",
  messagingSenderId: "318036386965",
  appId: "1:318036386965:web:45b11b1d74c4b73b16679c",
};
initializeApp(firebaseConfig);
const messaging = getMessaging();
export const getFBToken = async () => {
  getToken(messaging, { vapidKey: publicKey })
    .then((currentToken) => {
      if (currentToken) {
        updateDeviceToken(currentToken);
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // ...
    });
};

const updateDeviceToken = async (token: string) => {
  const bearerToken = localStorage.getItem("token");
  if (!bearerToken) {
    return;
  }
  await client
    .mutate({
      mutation: UPDATE_DEVICE_TOKEN,
      variables: {
        token: token,
      },
    })
    .then((response) => onSuccess(response))
    .catch((error) => console.log(error));
};

const onSuccess = (response: FetchResult) => {};
