import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../utilities/variables";

type Props = {
  color?: string;
  title: string;
  link: string;
};

const StyledLink = styled((props) => <Link {...props} />)`
  color: ${(props) => (props.$color ? props.$color : colors.grey)};
`;

export const SimpleLink: React.ComponentType<Props> = ({
  color,
  title,
  link,
}) => {
  return (
    <StyledLink className={"font-medium"} $color={color} to={link}>
      {title}
    </StyledLink>
  );
};
