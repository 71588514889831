import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import EngrosAppenLogo from "../../assets/engros-appen-logo.svg";
import styled from "styled-components";
import { colors } from "../../utilities/variables";
import { mdiMenu } from "@mdi/js";
import IconButton from "@material-ui/core/IconButton";
import { withTheme } from "@material-ui/styles";
import Icon from "@mdi/react";
import { useSelector } from "../../store/hooks";
import { CustomerMenu } from "./CustomerMenu";
import { TopMenuLink } from "../molecules/TopMenuLink";
import { NavLink, useHistory } from "react-router-dom";
import { AppDownloadMenu } from "./AppDownloadMenu";

type Props = {
  position?: "fixed" | "static";
  showBorder?: boolean;
  handleDrawerOpen?: () => void;
  showLogin?: boolean;
};

const LogoWrapper = styled(NavLink)``;
const Logo = styled.img``;

const MainNavBar = styled((props) => <AppBar {...props} />)`
  background-color: ${colors.white} !important;
  ${(props) =>
    props.$showBorder && `border-bottom: 1px solid ${colors.lightGrey};`}
`;

const MenuButton = withTheme(styled(IconButton)`
  color: ${colors.primary};
  margin-right: ${(props) => props.theme.spacing(2)}px;
  ${(props) => props.theme.breakpoints.up("sm")} {
    display: none;
  }
`);

export const NavBar: React.ComponentType<Props> = ({
  position = "static",
  showBorder = false,
  handleDrawerOpen,
  showLogin = true,
}) => {
  const { user } = useSelector((state) => state.user);
  const history = useHistory();

  return (
    <Box>
      <MainNavBar
        position={position}
        elevation={0}
        sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        $showBorder={showBorder}
      >
        <Toolbar className={"justify-between"}>
          <MenuButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
          >
            <Icon path={mdiMenu} size={1} />
          </MenuButton>
          <LogoWrapper to="/">
            <Logo src={EngrosAppenLogo} alt={"EngrosAppen logo"} />
          </LogoWrapper>
          {user.id !== 0 && (
            <div className={"sm:flex hidden h-full"}>
              <TopMenuLink link={"/listings"} label={"Hjem"} />
              <TopMenuLink link={"/my-items"} label={"Mine annoncer"} />
              <TopMenuLink link={"/post-item"} label={"Ny annonce"} />
              <TopMenuLink link={"/messages"} label={"Beskeder"} />
            </div>
          )}
          <div className="flex justify-center items-center">
            <AppDownloadMenu />
            <div className="mx-2"></div>

            {user.id !== 0 && <CustomerMenu user={user} />}
            {user.id === 0 && showLogin && (
              <div
                className="text-primary-gradient cursor-pointer"
                onClick={() => history.push("/login")}
              >
                Log ind
              </div>
            )}
          </div>
        </Toolbar>
      </MainNavBar>
    </Box>
  );
};
