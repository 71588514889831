import { gql } from "@apollo/client";

const GET_POST = gql`
  query getPost($uuid: String!) {
    getPost(uuid: $uuid) {
      id
      uuid
      title
      description
      askForBids
      images {
        id
        url
      }
      price
      createdAt
      address
      city
      zip
      sold
      active
      isFavorite
      categories {
        id
        title
      }
      brand {
        id
        title
      }
      customer {
        id
        company
        address
        city
        zip
        email
        phone
        image
      }
    }
  }
`;

export default GET_POST;
