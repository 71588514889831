import { gql } from "@apollo/client";

const CREATE_MESSAGE = gql`
  mutation createMessage($threadId: Int!, $message: String!) {
    createMessage: createMessage(
      input: { threadId: $threadId, message: $message }
    ) {
      id
      message
      createdAt
      owner {
        id
        name
      }
    }
  }
`;

export default CREATE_MESSAGE;
