import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import { FilterButton } from "../atoms/FilterButton";
import { Category } from "../../types/Post";
import { FilterItem } from "../atoms/FilterItem";
import { Button } from "../atoms/Button";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 260,
    border: "none",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const FooterButtons = styled((props) => <div {...props} />)(({ theme }) => ({
  boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.03)",
  padding: "8px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

type Props = {
  categories: [Category] | [];
  apply: (categories: [number]) => void;
  clear: () => void;
  count: number;
  parentSelectedCategories: number[];
};
export const FilterCategories: React.ComponentType<Props> = ({
  categories,
  apply,
  clear,
  count,
  parentSelectedCategories,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedCategories, setSelectedCategories] = React.useState<number[]>(
    parentSelectedCategories
  );

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setSelectedCategories(parentSelectedCategories);
  }, [parentSelectedCategories]);

  const handleOnClick = (id: number) => {
    if (selectedCategories.includes(id as never)) {
      const newSelectedCategories = selectedCategories.filter(
        (f) => f !== id
      ) as [number];
      setSelectedCategories(newSelectedCategories);
    } else {
      const newSelectedCategories = [...selectedCategories, id] as [number];

      setSelectedCategories(newSelectedCategories);
    }
  };

  const applyFilters = () => {
    handleClose();
    apply(selectedCategories as [number]);
  };

  const clearFilters = () => {
    handleClose();
    setSelectedCategories([]);
    clear();
  };

  return (
    <div>
      <FilterButton onClick={handleClick} title={"Kategori"} count={count} />

      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="p-4">
          {categories.map((category) => {
            return (
              <FilterItem
                id={category.id}
                key={category.id}
                title={category.title}
                onClick={handleOnClick}
                isSelected={selectedCategories.includes(category.id as never)}
              />
            );
          })}
        </div>
        <FooterButtons>
          <div>
            <span
              onClick={clearFilters}
              className="font-medium text-sm cursor-pointer"
            >
              Ryd
            </span>
          </div>
          <div>
            <Button
              radius={60}
              useMargin={false}
              fontSize={"text-sm"}
              defineHeight={false}
              loading={false}
              onClick={applyFilters}
              title={"Søg"}
              primary={true}
              width={110}
            />
          </div>
        </FooterButtons>
      </StyledMenu>
    </div>
  );
};
