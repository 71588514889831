import { gql } from "@apollo/client";

const GET_FAVORITE_POSTS = gql`
  query {
    getFavorites {
      id
      uuid
      sold
      images {
        id
        url
      }
      title
      price
      createdAt
    }
  }
`;

export default GET_FAVORITE_POSTS;
