import React from "react";
import { useHistory } from "react-router-dom";
import { Card } from "../../component/atoms/Card";
import { Button } from "../../component/atoms/Button";
import { TitleWithSubtitle } from "../../component/atoms/TitleWithSubtitle";
import { LoginLayout } from "../../component/organisms/LoginLayout";
import { OutlinedButton } from "../../component/atoms/OutlinedButton";
import styled from "styled-components";
import { colors } from "../../utilities/variables";

type Props = {};

const Subtitle = styled.div`
  color: ${colors.grey};
  font-size: 16px;
`;

const List = styled.ul`
  list-style: inside;
`;

export const Start: React.ComponentType<Props> = () => {
  const history = useHistory();

  return (
    <LoginLayout>
      <Card>
        <TitleWithSubtitle title={"Velkommen til EngrosAppen"} subtitle={""} />

        <Subtitle className="mb-5">
          <p className="mb-5">
            Vi samler de seriøse handler i autobranchen hvor vi kan bytte og
            handle indbyrdes helt salærfri. Den "gamle" Engrosfax genopstår!
            Tilmeld dig og vær med til ar skabe et fælles netværk for din
            branche.
          </p>
          <List>
            <li>Salærfri BTB-handel</li>
            <li>Kun til CVR relateret til autobranchen</li>
            <li>Danmarks største netværk i autobranchen</li>
          </List>
        </Subtitle>
        <div className={"flex flex-wrap"}>
          <div className="w-1/2 sm:pr-2">
            <Button
              onClick={() => {
                history.push("/signup");
              }}
              loading={false}
              title={"Registrér"}
              primary={true}
            />
          </div>
          <div className="w-1/2 sm:pl-2">
            <OutlinedButton
              title={"Log ind"}
              onClick={() => {
                history.push("/login");
              }}
              color={"text-primary-green"}
            />
          </div>
        </div>
        <div className="text-center mt-5">
          <button
            onClick={() => {
              history.push("/listings");
            }}
          >
            Fortsæt uden at registrér
          </button>
        </div>
      </Card>
    </LoginLayout>
  );
};
