import { gql } from "@apollo/client";

const GET_POSTS_WITH_MY_THREAD = gql`
  query {
    getPostsWithMyThreads {
      id
      uuid
      title
      createdAt
      sold
      price
      images {
        id
        url
      }
      customer {
        company
      }
    }
  }
`;

export default GET_POSTS_WITH_MY_THREAD;
