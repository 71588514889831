import React from "react";
import { AppLayout } from "../../component/organisms/AppLayout";
import { Card } from "../../component/atoms/Card";
import { client } from "../../utilities/Apollo";
import { ApolloError, FetchResult } from "@apollo/client";
import { useParams, useHistory } from "react-router";
import { colors } from "../../utilities/variables";
import { Avatar, Breadcrumbs, CircularProgress } from "@material-ui/core";
import GET_POST from "../../query/Post/getPost";
import { Category, Post } from "../../types/Post";
import { formatDistance } from "date-fns";
import styled from "styled-components";
import { PostDetailItem } from "../../component/atoms/PostDetailItem";
import { CompanyDetailItem } from "../../component/atoms/CompanyDetailItem";
import { Icon } from "../../component/atoms/Icon";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { CarouselArrow } from "../../component/atoms/CarouselArrow";
import { Button } from "../../component/atoms/Button";
import { Link } from "react-router-dom";
import SET_POST_FAVORITE from "../../query/Post/setPostFavorite";
import { FavoriteIcon } from "../../component/atoms/FavoriteIcon";
import { useSelector } from "../../store/hooks";
import GET_THREADS from "../../query/Message/getThreads";
import { Thread } from "../../types/Thread";
import GET_FAVORITE_COUNT from "../../query/Post/getPostFavoriteCount";
import { ChipComponent } from "../../component/atoms/Chip";
import { ThreadChatItem } from "../../component/atoms/ThreadChatItem";
import { useDispatch } from "../../store/hooks";
import { modalSlice } from "../../store/slices/modal";
import { PostMenu } from "../../component/atoms/PostMenu";
import { DeleteListing } from "../../component/molecules/DeleteListing";
import { MembershipCard } from "../../component/atoms/MembershipCard";
import { da } from "date-fns/locale";
import GET_ANONYMOUS_POST from "../../query/Post/getAnonymousPost";

type Props = {};
interface RouteParams {
  uuid: string;
}

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.lightGrey};
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;

const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const StyledCarousel = styled(Carousel)`
  .carousel {
    position: relative;
    .control-dots {
      display: flex;
      justify-content: center;
      align-items: center;
      .dot.selected {
        width: 12px;
        height: 12px;
      }
    }
    .slide {
      img {
        border-radius: 4px;
      }
    }
    .thumbs-wrapper {
      margin: 0;
      margin-top: 20px;
      .thumb {
        padding: 0;
        width: 70px !important;
        border: none;
        &.selected div {
          border: 1px solid ${colors.blue};
        }
      }
    }
  }
`;
const ThumbImage = styled((props) => <div {...props} />)`
  background: url("${(props) => props.background}");
  background-position: center;
  width: 70px;
  height: 70px;
  background-size: cover;
  border-radius: 4px;
  border: 1px solid ${colors.white};
`;

export const ListingDetail: React.ComponentType<Props> = () => {
  const params = useParams<RouteParams>();
  const [loadingData, setLoadingData] = React.useState(true);
  const [data, setData] = React.useState<Post | null>(null);
  const [isFavorite, setIsFavorite] = React.useState(false);
  const { user } = useSelector((state) => state.user);
  const [threads, setThreads] = React.useState<[Thread] | []>([]);
  const [favoriteCount, setFavoriteCount] = React.useState<number>(0);
  const dispatch = useDispatch();

  const [isOwner, setIsOwner] = React.useState(false);
  const history = useHistory();
  const getPost = React.useCallback(async () => {
    setLoadingData(true);
    if (user.id) {
      await client
        .query({ query: GET_POST, variables: { uuid: params.uuid } })
        .then((response) => onDataSuccess(response))
        .catch((error) => onDataError(error));
    } else {
      await client
        .query({ query: GET_ANONYMOUS_POST, variables: { uuid: params.uuid } })
        .then((response) => onAnonymousDataSuccess(response))
        .catch((error) => onDataError(error));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = React.useState(false);
  const handlePostFavorite = async (event?: Event) => {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);
    await client
      .mutate({
        mutation: SET_POST_FAVORITE,
        variables: {
          uuid: data?.uuid,
        },
      })
      .then((response) => onSuccess(response))
      .catch((error) => onError(error));
  };

  const onSuccess = (response: FetchResult) => {
    setLoading(false);
    setIsFavorite(response?.data?.handleFavorite);
  };

  const onError = (error: ApolloError) => {
    setLoading(false);
  };

  const onDataSuccess = (response: FetchResult) => {
    const post = response?.data?.getPost;
    setData(post);
    let checkIfOwner =
      post.customer.id === user.id || post.customer.id === user.ownerId;

    setIsOwner(checkIfOwner);
    setIsFavorite(response?.data?.getPost.isFavorite);
    if (checkIfOwner) {
      getThreads(post.id);
    } else {
      setLoadingData(false);
    }
  };

  const onAnonymousDataSuccess = (response: FetchResult) => {
    const post = response?.data?.getAnonymousPost;
    setData(post);
    setIsOwner(false);
    setIsFavorite(false);
    setLoadingData(false);
  };

  const getThreads = async (postId: number) => {
    await client
      .query({ query: GET_THREADS, variables: { id: params.uuid } })
      .then((response) => onThreadsSuccess(response, postId))
      .catch((error) => onDataError(error));
  };

  const onThreadsSuccess = async (response: FetchResult, postId: number) => {
    setThreads(response?.data?.getThreads);
    await getFavoriteCount(postId);
  };

  const getFavoriteCount = async (postId: number) => {
    await client
      .query({ query: GET_FAVORITE_COUNT, variables: { id: postId } })
      .then((response) => onFavoriteCountSuccess(response))
      .catch((error) => onDataError(error));
  };

  const onFavoriteCountSuccess = (response: FetchResult) => {
    setFavoriteCount(response?.data?.getPostFavoritesCount);
    setLoadingData(false);
  };

  const onDataError = (error: ApolloError) => {
    console.log(error);
  };

  React.useEffect(() => {
    async function fetchData() {
      await getPost();
    }
    fetchData();
  }, [getPost]);

  React.useEffect(() => {
    let checkIfOwner =
      data?.customer.id === user.id || data?.customer.id === user.ownerId;

    setIsOwner(checkIfOwner);
    if (checkIfOwner) {
      getThreads(data?.id as number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, data]);

  const renderCategories = (categories: Category[] | undefined) => {
    if (!categories) {
      return null;
    }
    return categories.map((category: Category, index: number) => {
      return (
        <React.Fragment key={category.id}>
          <span className={"text-grey-primary text-sm"}>{category.title}</span>
          {categories.length > index + 1 && (
            <span className={"text-grey-primary text-sm px-2 inline-block"}>
              |
            </span>
          )}
        </React.Fragment>
      );
    });
  };

  const customRenderThumb = (children: any) =>
    children.map((item: any, index: number) => {
      return (
        <ThumbImage background={item.props.children.props.src} key={index} />
      );
    });

  const goToChat = (thread: Thread) => {
    const location = {
      pathname: "/messages/thread/" + data?.uuid,
      state: { thread: thread },
    };
    history.push(location);
  };

  const openModal = React.useCallback(
    (uuid: string) => {
      dispatch(
        modalSlice.actions.update({
          deleteListing: true,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <AppLayout>
      <div className={"flex items-center justify-between"}>
        <Breadcrumbs
          separator={
            <Icon
              icon={"chevron-right"}
              size={"text-3xl"}
              color={"text-primary-black"}
            />
          }
          aria-label="breadcrumb"
        >
          <Link
            color="inherit"
            to={isOwner ? "/my-items" : "/listings"}
            className={"text-primary-black text-sm font-medium"}
          >
            {isOwner ? "Mine annonce" : "Hjem"}
          </Link>
          <span className={"text-primary-black text-sm font-medium"}>
            {data?.title}
          </span>
        </Breadcrumbs>
        {isOwner && <PostMenu post={data} openModal={openModal} />}
      </div>
      <Card width={"w-full"} padding={2} className={"mt-5"} rounded={"rounded"}>
        {loadingData ? (
          <div className={"text-center pt-8"}>
            <p>Vent venligst, data indlæses…</p>
            <div className={"text-primary-green mt-2"}>
              <CircularProgress color="inherit" />
            </div>
          </div>
        ) : (
          <div className={"flex flex-wrap"}>
            <div className={"sm:w-7/12 w-full flex justify-between"}>
              <div>
                <p className={"text-primary-green font-semibold text-2xl"}>
                  {data?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                  kr.
                </p>
                <p className={"font-semibold text-2xl"}>{data?.title}</p>
              </div>
              <div>
                {!isOwner && user.id !== 0 && (
                  <FavoriteIcon
                    loadingColor="text-primary-green"
                    color="text-grey-primary"
                    filledColor="text-primary-yellow"
                    loading={loading}
                    handlePostFavorite={handlePostFavorite}
                    isFavorite={isFavorite}
                  />
                )}
                {isOwner && (
                  <div className={"flex items-center"}>
                    <ChipComponent
                      title={favoriteCount.toString()}
                      textColor={colors.grey}
                      backgroundColor={colors.inputField}
                    />
                    <div className={"ml-1 flex items-center"}>
                      <Icon
                        icon={"filled-star"}
                        color={"text-primary-yellow"}
                        size={"text-3xl"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={"sm:w-7/12 w-full"}>
              <div className={"flex justify-between"}>
                <div>{renderCategories(data?.categories)}</div>
                <div className={"text-grey-primary text-sm"}>
                  {" "}
                  {/* {formatDistance(
                    new Date(data?.createdAt ?? new Date()),
                    new Date(),
                    {
                      addSuffix: true,
                      locale: da,
                    }
                  )}*/}
                </div>
              </div>
            </div>
            <div className={"sm:w-7/12 w-full"}>
              <div className={"mt-4"}>
                <div className={""}>
                  <StyledCarousel
                    showStatus={false}
                    renderThumbs={customRenderThumb}
                    renderArrowPrev={(clickHandler, hasPrev, label) => {
                      return (
                        <CarouselArrow
                          clickHandler={clickHandler}
                          hasAnother={hasPrev}
                          position={"left"}
                        />
                      );
                    }}
                    renderArrowNext={(clickHandler, hasNext, label) => {
                      return (
                        <CarouselArrow
                          clickHandler={clickHandler}
                          hasAnother={hasNext}
                          position={"right"}
                        />
                      );
                    }}
                  >
                    {data?.images.map((image) => {
                      return (
                        <div key={image.id}>
                          <img src={image.url} alt={data?.title} />
                        </div>
                      );
                    })}
                  </StyledCarousel>
                </div>
                <Divider />
                <SectionTitle>Beskrivelse</SectionTitle>
                <div className={"mt-2 mb-4"}>{data?.description}</div>
                <SectionTitle className={"mb-2 mt-5"}>Detaljer</SectionTitle>
                <PostDetailItem title={"Mærke"} value={data?.brand?.title} />
                <PostDetailItem
                  title={"Lokation"}
                  value={data?.address + " " + data?.city + " " + data?.zip}
                />
              </div>
            </div>
            <div className={"w-1/12"}></div>
            <div className={"sm:w-4/12 w-full"}>
              {!isOwner &&
                user.activeMembership.type !== "free" &&
                user.id !== 0 && (
                  <div className={"mt-4"}>
                    <SectionTitle className={"mb-2"}>Sælger</SectionTitle>
                    <div className={"flex items-center mb-2"}>
                      {data?.customer.image == null && (
                        <Avatar>
                          <Icon icon={"profile"} size={"text-2xl"} />
                        </Avatar>
                      )}
                      {data?.customer.image && (
                        <Avatar src={data?.customer.image} />
                      )}
                      <p className={"font-semibold ml-3"}>
                        {data?.customer.company}
                      </p>
                    </div>

                    <CompanyDetailItem
                      icon={"pin"}
                      value={
                        data?.customer.address +
                        ", " +
                        data?.customer.zip +
                        " " +
                        data?.customer.city
                      }
                    />
                    <CompanyDetailItem
                      icon={"phone"}
                      value={data?.customer.phone}
                    />
                    <Button
                      title={"Chat"}
                      onClick={() =>
                        history.push("/messages/post/" + data?.uuid)
                      }
                      loading={false}
                      defineHeight={false}
                    />
                  </div>
                )}
              {!isOwner && user.activeMembership.type === "free" && (
                <div className="flex flex-col items-center">
                  <div className="my-1">Se detaljer med Premium</div>
                  <MembershipCard
                    onButtonClick={() => history.push("/membership")}
                  />
                </div>
              )}
              {user.id === 0 && (
                <>
                  <div className="my-1 text-center">Log venligst ind først</div>
                  <Button
                    primary={true}
                    title={"Registrér eller log ind"}
                    onClick={() => history.push("/start")}
                    loading={false}
                    defineHeight={false}
                  />
                </>
              )}
              {isOwner && (
                <div className={"mt-4"}>
                  <SectionTitle className={"mb-2"}>Beskeder</SectionTitle>
                  {threads.map((thread, index) => {
                    return (
                      <ThreadChatItem
                        thread={thread}
                        key={thread.id}
                        isLast={threads.length - 1 === index}
                        isActive={false}
                        onClick={() => goToChat(thread)}
                      />
                    );
                  })}
                  {threads.length === 0 && (
                    <p className="text-grey-primary text-sm">
                      Der er ingen beskeder
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </Card>
      <DeleteListing
        deletePostItem={params.uuid}
        callback={() => history.push("/my-items")}
      />
    </AppLayout>
  );
};
