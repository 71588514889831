import React from "react";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { colors, translations } from "../../utilities/variables";
import classNames from "classnames";
import { Icon } from "./Icon";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";

type Props = {
  onSubmitForm: (data: any) => void;
  resetSearchForm: () => void;
  label: string;
  width?: string;
};

interface IFormInput {
  search: string;
}

const schema = yup.object().shape({
  search: yup.string().required(translations.required),
});

const StyledTextField = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  fontFamily: "Poppins",
  "& .MuiFilledInput-root": {
    fontFamily: "Poppins",
    border: "none",
    overflow: "hidden",
    borderRadius: 60,
    backgroundColor: colors.white,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: colors.white,
    },
    "&.Mui-focused": {
      backgroundColor: colors.white,
    },
    "&.Mui-disabled": {
      backgroundColor: colors.white,
    },
  },
  input: {
    padding: "8.5px 14px",
  },
  "& .MuiInputLabel-root": {
    color: colors.grey,
    fontFamily: "Poppins",
    "&.Mui-focused": {
      color: colors.grey,
    },
  },
}));

export const SearchInput: React.ComponentType<
  Props & { ref?: React.Ref<any> }
> = React.forwardRef(
  ({ onSubmitForm, resetSearchForm, label, width = "w-full" }, ref) => {
    const { register, handleSubmit, trigger, setValue, reset } =
      useForm<IFormInput>({
        resolver: yupResolver(schema),
      });

    const [text, setText] = React.useState("");

    React.useEffect(() => {
      register("search", { required: true });
    }, [register]);

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
      trigger();
      onSubmitForm(data.search);
    };

    const resetSearch = () => {
      reset();
      setText("");
      resetSearchForm();
    };

    React.useImperativeHandle(ref, () => ({
      resetFromParent: () => {
        reset();
        setText("");
      },
    }));

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={classNames(
            "bg-white rounded-3xl flex items-center",
            width
          )}
        >
          <StyledTextField
            placeholder={label}
            name={"search"}
            type={"text"}
            size={"small"}
            value={text}
            variant="filled"
            onChange={async (e: any) => {
              setText(e.target.value);
              setValue(e.target.name, e.target.value);
            }}
            helperText={""}
          />

          <div className="flex items-center pr-5 cursor-pointer w-10">
            {text !== "" && (
              <div onClick={resetSearch} className="flex items-center">
                <Icon
                  icon={"close"}
                  color={"text-grey-primary"}
                  size={"text-3xl"}
                />
              </div>
            )}
          </div>
        </div>
      </form>
    );
  }
);
