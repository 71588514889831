import React from "react";
import styled from "styled-components";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Card } from "./Card";
import { colors } from "../../utilities/variables";
import { useDispatch } from "../../store/hooks";
import { backdropSlice } from "../../store/slices/backdrop";
import { client } from "../../utilities/Apollo";
import { ApolloError, FetchResult } from "@apollo/client";
import { snackbarSlice } from "../../store/slices/snackbarSlice";
import { FabButton } from "./FabButton";
import { Post } from "../../types/Post";
import UPDATE_POST_STATUS from "../../query/Post/updatePostStatus";
import { useHistory } from "react-router-dom";

type Props = {
  post: Post | null;
  callback?: () => void;
  openModal: (uuid: string) => void;
};

const StyledMenu = styled((props) => <Menu {...props} />)`
  .MuiPaper-root {
    box-shadow: 0px 5px 10px rgba(28, 33, 52, 0.05) !important;
  }
`;

const StyledMenuItem = styled((props) => <MenuItem {...props} />)`
  font-family: Poppins !important;
  color: ${(props) =>
    props.$isError ? colors.error : colors.black} !important;
`;

export const PostMenu: React.ComponentType<Props> = ({
  post,
  callback,
  openModal,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBackdrop = (state: boolean) => {
    dispatch(
      backdropSlice.actions.put({
        open: state,
      })
    );
  };

  const handleSnackbar = (message: string, severity: string) => {
    dispatch(
      snackbarSlice.actions.put({
        open: true,
        message: message,
        severity: severity,
      })
    );
  };

  const updatePostStatus = async (statusItem: string) => {
    handleBackdrop(true);
    await client
      .mutate({
        mutation: UPDATE_POST_STATUS,
        variables: {
          id: post?.id,
          active: statusItem === "active" ? !post?.active : post?.active,
          sold: statusItem === "sold" ? !post?.sold : post?.sold,
        },
      })
      .then((response) => onSuccess(response))
      .catch((error) => onActionError(error));
  };

  const onSuccess = async (response: FetchResult) => {
    handleBackdrop(false);
    handleSnackbar("Status has been updated", "success");
    handleClose();
    if (callback) {
      callback();
    }
  };

  const onActionError = (error: ApolloError) => {
    handleBackdrop(false);
  };

  const openModalAndSetPost = () => {
    handleClose();
    openModal(post?.uuid as string);
  };

  return (
    <div>
      <FabButton
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </FabButton>

      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Card width={"w-full"} padding={0.7}>
          <StyledMenuItem
            onClick={() => history.push("/edit-post-item/" + post?.uuid)}
          >
            Redigér annonce
          </StyledMenuItem>
          <StyledMenuItem onClick={() => updatePostStatus("active")}>
            Markér som {post?.active ? "inaktiv" : "aktiv"}
          </StyledMenuItem>
          <StyledMenuItem onClick={() => updatePostStatus("sold")}>
            Markér som {post?.sold ? "unsolgt" : "solgt"}
          </StyledMenuItem>
          <StyledMenuItem onClick={openModalAndSetPost} $isError={true}>
            Slet annonce
          </StyledMenuItem>
        </Card>
      </StyledMenu>
    </div>
  );
};
