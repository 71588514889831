import * as React from "react";
import styled from "styled-components";
import { colors } from "../../utilities/variables";
import { Icon } from "./Icon";
import Camera from "../../assets/icons/icon-camera.svg";

type Props = {};

const OutterCircle = styled.div`
  position: relative;
  width: 58px;
  height: 58px;
  background-color: ${colors.lightGreen};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 40px;
`;

const InnerCircle = styled.div`
  width: 48px;
  height: 48px;
  background-color: ${colors.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CameraIcon: React.ComponentType<Props> = () => {
  return (
    <OutterCircle>
      <InnerCircle>
        <img src={Camera} alt="camera icon" />
      </InnerCircle>
      <div className="absolute top-0 right-0 bg-white rounded-xl w-6 h-6 flex items-center justify-center">
        <Icon icon={"plus"} color={"text-primary-green"} />
      </div>
    </OutterCircle>
  );
};
