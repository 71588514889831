import React from "react";
import { AppLayout } from "../../component/organisms/AppLayout";
import { Card } from "../../component/atoms/Card";

type Props = {};
export const NotFound: React.ComponentType<Props> = () => {
  return (
    <AppLayout>
      <Card>
        <div className="text-center">
          <div className="my-4 font-semibold text-3xl">404</div>
          <div>Siden findes ikke</div>
        </div>
      </Card>
    </AppLayout>
  );
};
