import React, { useState } from "react";
import { publicClient } from "../../utilities/Apollo";
import Alert from "@material-ui/lab/Alert";
import { useForm, SubmitHandler } from "react-hook-form";
import { ApolloError, FetchResult } from "@apollo/client";
import { Card } from "../../component/atoms/Card";
import { translations } from "../../utilities/variables";
import { InputField } from "../../component/atoms/InputField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "../../component/atoms/Button";
import { TitleWithSubtitle } from "../../component/atoms/TitleWithSubtitle";
import { LoginLayout } from "../../component/organisms/LoginLayout";
import { SimpleLink } from "../../component/atoms/SimpleLink";
import RESET_PASSWORD from "../../query/Auth/resetPassword";

type Props = {};

interface IFormInput {
  email: string;
}

const schema = yup.object().shape({
  email: yup.string().required(translations.required).email("Ugyldig email"),
});

export const ResetPassword: React.ComponentType<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isComplete, setIsComplete] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
    getValues,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    trigger();
    await resetPassword(data);
  };

  const resetPassword = async (data: any) => {
    setLoading(true);
    setError(null);
    await publicClient
      .mutate({
        mutation: RESET_PASSWORD,
        variables: {
          email: data.email,
        },
      })
      .then((response) => onResetSuccess(response))
      .catch((error) => onResetError(error));
  };

  const onResetSuccess = async (response: FetchResult) => {
    setLoading(false);
    setIsComplete(true);
  };

  const onResetError = (error: ApolloError) => {
    setLoading(false);
    setError(error.graphQLErrors[0].message);
  };

  React.useEffect(() => {
    register("email", { required: true });
  }, [register]);

  return (
    <LoginLayout>
      <Card>
        {!isComplete && (
          <TitleWithSubtitle
            title={"Reset password..."}
            subtitle={
              "Indtast din email, så sender vi dig en mail for at få et nyt password"
            }
          />
        )}

        {isComplete && (
          <TitleWithSubtitle
            title={"Email er sendt"}
            subtitle={`Vi har lige sent en mail til ${getValues(
              "email"
            )} med et link til at få et nyt password`}
          />
        )}

        {error && (
          <Alert severity="error" className={"my-3"}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col"}>
          {!isComplete && (
            <>
              <div className={"h-16 w-full"}>
                <InputField
                  label={"Email addresse"}
                  defaultValue=""
                  name="email"
                  error={errors.email?.message}
                  onChange={async (e: any, data: any) => {
                    setValue(e.target.name, e.target.value);
                  }}
                />
              </div>
              <Button
                onClick={handleSubmit(onSubmit)}
                loading={loading}
                title={"Send"}
                primary={true}
              />
            </>
          )}
          {isComplete && (
            <>
              <div className={"h-16 w-full"} />
              <Button
                onClick={() => resetPassword({ email: getValues("email") })}
                loading={loading}
                title={"Send igen"}
                primary={true}
              />
            </>
          )}
        </form>

        <div className={"text-center mt-4"}>
          <SimpleLink link={"/login"} title={"Tilbage til Login"} />
        </div>
      </Card>
    </LoginLayout>
  );
};
