import React, { useState } from "react";
import { client, publicClient } from "../../utilities/Apollo";
import LOGIN_USER from "../../query/Auth/loginUser";
import { useDispatch } from "../../store/hooks";
import { userSlice } from "../../store/slices/userSlice";
import { useHistory } from "react-router-dom";
import GET_CURRENT_USER from "../../query/User/getCurrent";
import Alert from "@material-ui/lab/Alert";
import { useForm, SubmitHandler } from "react-hook-form";
import { ApolloError, FetchResult } from "@apollo/client";
import { Card } from "../../component/atoms/Card";
import { translations } from "../../utilities/variables";
import { InputField } from "../../component/atoms/InputField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "../../component/atoms/Button";
import { TitleWithSubtitle } from "../../component/atoms/TitleWithSubtitle";
import { LoginLayout } from "../../component/organisms/LoginLayout";
import { SimpleLink } from "../../component/atoms/SimpleLink";

type Props = {};

interface IFormInput {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().required(translations.required).email("Ugyldig email"),
  password: yup.string().required(translations.required),
});

export const Login: React.ComponentType<Props> = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    trigger();
    await signIn(data);
  };

  const signIn = async (data: any) => {
    setLoading(true);
    setError(null);
    await publicClient
      .mutate({
        mutation: LOGIN_USER,
        variables: {
          email: data.email,
          password: data.password,
        },
      })
      .then((response) => onSignInSuccess(response))
      .catch((error) => onSignInError(error));
  };

  const onSignInSuccess = async (response: FetchResult) => {
    let token = response?.data?.login;
    if (token) {
      localStorage.setItem("token", token);
      await getCurrentUser();
    }
  };

  const onSignInError = (error: ApolloError) => {
    setLoading(false);
    setError(error.graphQLErrors[0].message);
  };

  const getCurrentUser = async () => {
    await client
      .query({ query: GET_CURRENT_USER })
      .then((response) => onGetCurrentUserSuccess(response))
      .catch(() => onGetCurrentError());
  };

  const onGetCurrentUserSuccess = async (response: FetchResult) => {
    setLoading(false);
    dispatch(
      userSlice.actions.put({
        initialized: true,
        user: response?.data?.currentCustomer,
      })
    );

    history.push("/listings");
  };

  const onGetCurrentError = () => {
    setLoading(false);
  };

  React.useEffect(() => {
    register("email", { required: true });
    register("password", { required: true });
  }, [register]);

  return (
    <LoginLayout>
      <Card>
        <TitleWithSubtitle
          title={"Velkommen"}
          subtitle={"Find de bedste deals og opret dine egne"}
        />

        {error && (
          <Alert severity="error" className={"my-3"}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col"}>
          <InputField
            label={"Email"}
            defaultValue=""
            name="email"
            error={errors.email?.message}
            onChange={async (e: any, data: any) => {
              setValue(e.target.name, e.target.value);
            }}
          />

          <InputField
            type={"password"}
            label={"Kodeord"}
            defaultValue=""
            name="password"
            error={errors.password?.message}
            onChange={async (e: any, data: any) => {
              setValue(e.target.name, e.target.value);
            }}
          />

          <Button
            onClick={handleSubmit(onSubmit)}
            loading={loading}
            title={"Log ind"}
            primary={true}
          />
        </form>
        <div className={"text-center mt-4"}>
          <SimpleLink link={"/reset-password"} title={"Glemt kodeord?"} />
        </div>
      </Card>
    </LoginLayout>
  );
};
