import React from "react";
import styled from "styled-components";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Card } from "./Card";
import { Customer } from "../../types/Customer";
import { colors } from "../../utilities/variables";
import { UserAvatar } from "./UserAvatar";
import { useDispatch } from "../../store/hooks";
import { useHistory } from "react-router";
import { storeClear } from "../../store/actions/storeClear";
import { Icon } from "./Icon";

type Props = {
  user: Customer;
};

const StyledMenu = styled((props) => <Menu {...props} />)`
  .MuiPaper-root {
    box-shadow: 0px 5px 10px rgba(28, 33, 52, 0.05) !important;
    min-width: 180px;
  }
`;

const StyledMenuItem = styled((props) => <MenuItem {...props} />)`
  font-family: Poppins !important;
  color: ${(props) =>
    props.$isError ? colors.error : colors.black} !important;
`;

export const CustomerMenu: React.ComponentType<Props> = ({ user }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    handleClose();
    dispatch(storeClear());
    localStorage.removeItem("token");
    history.push("/login");
  };

  return (
    <div>
      <div
        className={"cursor-pointer flex items-center"}
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <UserAvatar user={user} />
        <Icon
          icon={"chevron-down"}
          color={"text-grey-light"}
          className={"pl-2"}
        />
      </div>

      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Card width={"w-full"} padding={0.7}>
          <StyledMenuItem onClick={() => history.push("/profile")}>
            Profil
          </StyledMenuItem>
          <StyledMenuItem onClick={() => history.push("/service-catalog")}>
            Service Katalog
          </StyledMenuItem>
          <StyledMenuItem onClick={handleLogout}>Log ud</StyledMenuItem>
        </Card>
      </StyledMenu>
    </div>
  );
};
