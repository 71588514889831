import { gql } from "@apollo/client";

const GET_FILTERS = gql`
  query {
    getFilters {
      categories {
        id
        title
      }
      brands {
        id
        title
      }
    }
  }
`;

export default GET_FILTERS;
