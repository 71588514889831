import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import { FilterButton } from "../atoms/FilterButton";
import { FilterItem } from "../atoms/FilterItem";
import { Button } from "../atoms/Button";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 320,
    border: "none",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const FooterButtons = styled((props) => <div {...props} />)(({ theme }) => ({
  boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.03)",
  padding: "8px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

type Props = {
  apply: (regions: [string]) => void;
  clear: () => void;
  count: number;
};

const regions = [
  {
    id: "nord-og-ostsjaelland-og-bornholm",
    title: "Nord- og Østsjælland og Bornholm",
  },
  { id: "syd-og-vestsjaelland", title: "Syd- og Vestsjælland" },
  { id: "fyn", title: "Fyn" },
  { id: "syd-og-sonderjylland", title: "Syd- og Sønderjylland" },
  { id: "midt-og-vestjylland", title: "Midt- og Vestjylland" },
  { id: "ostjylland", title: "Østjylland" },
  { id: "nordjylland", title: "Nordjylland" },
];
export const FilterRegions: React.ComponentType<Props> = ({
  apply,
  clear,
  count,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedRegions, setSelectedRegions] = React.useState<[string] | []>(
    []
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClick = (id: string) => {
    if (selectedRegions.includes(id as never)) {
      const newSelectedRegions = selectedRegions.filter((f) => f !== id) as [
        string
      ];
      setSelectedRegions(newSelectedRegions);
    } else {
      const newSelectedRegions = [...selectedRegions, id] as [string];

      setSelectedRegions(newSelectedRegions);
    }
  };

  const applyFilters = () => {
    handleClose();
    apply(selectedRegions as [string]);
  };

  const clearFilters = () => {
    handleClose();
    setSelectedRegions([]);
    clear();
  };

  return (
    <div>
      <FilterButton onClick={handleClick} title={"Regioner"} count={count} />

      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="p-4">
          {regions.map((region) => {
            return (
              <FilterItem
                id={region.id}
                key={region.id}
                title={region.title}
                onClick={handleOnClick}
                isSelected={selectedRegions.includes(region.id as never)}
              />
            );
          })}
        </div>
        <FooterButtons>
          <div>
            <span
              onClick={clearFilters}
              className="font-medium text-sm cursor-pointer"
            >
              Ryd
            </span>
          </div>
          <div>
            <Button
              radius={60}
              useMargin={false}
              fontSize={"text-sm"}
              defineHeight={false}
              loading={false}
              onClick={applyFilters}
              title={"Søg"}
              primary={true}
              width={110}
            />
          </div>
        </FooterButtons>
      </StyledMenu>
    </div>
  );
};
