import { gql } from "@apollo/client";

const CREATE_POST = gql`
  mutation createPost(
    $title: String!
    $description: String!
    $address: String
    $city: String
    $zip: String
    $brandId: Int
    $price: String
    $status: String
    $categories: String
    $askForBids: Boolean
    $allowChat: Boolean
    $images: String
  ) {
    createPost: createPost(
      input: {
        title: $title
        description: $description
        address: $address
        city: $city
        zip: $zip
        brandId: $brandId
        price: $price
        status: $status
        categories: $categories
        askForBids: $askForBids
        allowChat: $allowChat
        images: $images
      }
    ) {
      id
      uuid
      title
      createdAt
      status
    }
  }
`;

export default CREATE_POST;
