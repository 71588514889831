import {
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import React from "react";
import { Card } from "../../component/atoms/Card";
import { AppLayout } from "../../component/organisms/AppLayout";
import { useDispatch, useSelector } from "../../store/hooks";
import { FormTitleSubtitle } from "../../component/atoms/FormTitleSubtitle";
import { ApolloError, FetchResult } from "@apollo/client";
import { client } from "../../utilities/Apollo";
import { ProfileHeader } from "../../component/molecules/ProfileHeader";
import { ProfileMenu } from "../../component/molecules/ProfileMenu";
import { colors, translations } from "../../utilities/variables";
import Alert, { Color } from "@material-ui/lab/Alert";
import { Hint } from "../../component/atoms/Hint";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { MembershipCard } from "../../component/atoms/MembershipCard";
import { backdropSlice } from "../../store/slices/backdrop";
import CREATE_MEMBERSHIP from "../../query/User/createMembership";
import GET_CURRENT_USER from "../../query/User/getCurrent";
import { userSlice } from "../../store/slices/userSlice";
import { snackbarSlice } from "../../store/slices/snackbarSlice";
import MAKE_PURCHASE from "../../query/User/makeWebPurchase";
import { OutlinedButton } from "../../component/atoms/OutlinedButton";
import CANCEL_PLAN from "../../query/User/cancelPlan";
import { modalSlice } from "../../store/slices/modal";
import { Modal } from "../../component/molecules/Modal";
import { Button } from "../../component/atoms/Button";
import * as yup from "yup";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputField } from "../../component/atoms/InputField";
import MAKE_INVOICE_PURCHASE from "../../query/User/makeInvoicePayment";
import Checked from "../../assets/icons/check-circle.svg";
import Selected from "../../assets/icons/selected-circle.png";
import SEND_FEEDBACK from "../../query/User/sendFeedback";

type Props = {};

interface IFormInput {
  contactPerson: string;
  contactEmail: string;
  confirmation: boolean;
}

const schema = yup.object().shape({
  contactPerson: yup.string().required(translations.required),
  contactEmail: yup
    .string()
    .required(translations.required)
    .email("Ugyldig email"),
});

const feedbackReasons = [
  "For dyrt",
  "Bruger det ikke ret meget",
  "Vil gerne lukke min konto",
  "Andre",
];

const CheckboxLabel: React.FC = () => {
  return (
    <span className="text-grey-primary">
      Jeg accepter{" "}
      <a href="/Handlebetingelser.pdf" target="_blank" className="underline">
        handlebetingelser
      </a>
    </span>
  );
};

export const Membership: React.ComponentType<Props> = () => {
  const { user } = useSelector((state) => state.user);

  const [error, setError] = React.useState<string | null>("");
  const [sendingFeedback, setSendingFeedback] = React.useState(false);
  const [severity, setSeverity] = React.useState<Color>("error");
  const [showInvoiceForm, setInvoiceForm] = React.useState(false);
  const [feedback, setFeedback] = React.useState("For dyrt");

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    control,
    setValue,
    watch,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const confirmation = watch("confirmation");

  React.useEffect(() => {
    register("contactPerson", { required: true });
    register("contactEmail", { required: true });
  }, [register]);

  React.useEffect(() => {
    setValue("contactPerson", user?.name ?? "");
    setValue("contactPerson", user?.email ?? "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    trigger();
    handleModalClose("chooseMembership");
    handleBackdrop(true);
    await client
      .mutate({
        mutation: MAKE_INVOICE_PURCHASE,
        variables: {
          contactPerson: data.contactPerson,
          contactEmail: data.contactEmail,
        },
      })
      .then((response) => onInvoiceSuccess(response))
      .catch((error) => onFreeError(error));
  };

  const onInvoiceSuccess = async (response: FetchResult) => {
    handleSnackbar(
      "Du har en Premium medlemskab. En mail med betalings detaljer er sent til dig.",
      "success"
    );
    await getCurrentUser();
  };

  const handleBackdrop = (state: boolean) => {
    dispatch(
      backdropSlice.actions.put({
        open: state,
      })
    );
  };

  const makeWebPurchase = async () => {
    handleBackdrop(true);
    await client
      .mutate({
        mutation: MAKE_PURCHASE,
        variables: {
          status: "unpaid",
          amount: 99,
          paymentType: "credit_card",
        },
      })
      .then((response) => onPurchaseSuccess(response))
      .catch((error) => onFreeError(error));
  };

  const onPurchaseSuccess = (response: FetchResult) => {
    const paymentLink = response?.data?.makeWebPurchase as string;
    window.location.replace(paymentLink);
  };

  const createFreeMembership = async () => {
    if (user.activeMembership.type === "free") {
      return;
    }
    handleBackdrop(true);
    await client
      .mutate({
        mutation: CREATE_MEMBERSHIP,
        variables: {
          type: "free",
        },
      })
      .then((response) => onFreeSuccess(response))
      .catch((error) => onFreeError(error));
  };

  const onFreeSuccess = async (response: FetchResult) => {
    handleSnackbar("Du har en basis medlemskab.", "success");
    await getCurrentUser();
  };

  const onFreeError = (error: ApolloError) => {
    setError(error.graphQLErrors[0].message);
    setSeverity("error");
    handleBackdrop(false);
  };

  const getCurrentUser = async () => {
    await client
      .query({ query: GET_CURRENT_USER })
      .then((response) => onGetCurrentUserSuccess(response))
      .catch(() => onGetCurrentError());
  };

  const onGetCurrentUserSuccess = async (response: FetchResult) => {
    dispatch(
      userSlice.actions.put({
        initialized: true,
        user: response?.data?.currentCustomer,
      })
    );
    handleBackdrop(false);
  };

  const onGetCurrentError = () => {
    handleBackdrop(false);
  };

  const handleSnackbar = (message: string, severity: string) => {
    dispatch(
      snackbarSlice.actions.put({
        open: true,
        message: message,
        severity: severity,
      })
    );
  };

  const openModal = React.useCallback(
    (modal: string) => {
      dispatch(
        modalSlice.actions.update({
          [modal]: true,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleModalClose = (modal: string) => {
    dispatch(
      modalSlice.actions.update({
        [modal]: false,
      })
    );
  };

  const cancelPlan = async () => {
    handleModalClose("chooseMembership");
    handleBackdrop(true);
    await client
      .mutate({
        mutation: CANCEL_PLAN,
      })
      .then((response) => onCancelSuccess(response))
      .catch((error) => onFreeError(error));
  };

  const onCancelSuccess = async (response: FetchResult) => {
    handleModalClose("cancelPlan");
    handleSnackbar("Din konto er annulleret", "success");
    await getCurrentUser();
    openModal("feedbackForm");
  };

  React.useEffect(() => {
    if (user.id && user.ownerId !== null) {
      history.push("/profile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getHintPaymentPlanLabel = () => {
    switch (user.activeMembership.type) {
      case "premium":
        return "Du har Premium medlemskab";
      case "trial":
        return `Din gratis prøve på 30 dage er aktiv og udløber den ${format(
          new Date(user.activeMembership.dateTo),
          "d.M.y"
        )}.`;
      case "free":
      default:
        return "Du har en basis medlemskab";
    }
  };

  const chooseInvoicePayment = () => {
    setInvoiceForm(true);
  };

  const sendFeedback = async () => {
    setSendingFeedback(true);
    await client
      .mutate({
        mutation: SEND_FEEDBACK,
        variables: { text: feedback },
      })
      .then((response) => onFeedbackSuccess(response))
      .catch((error) => onFreeError(error));
  };

  const onFeedbackSuccess = (response: FetchResult) => {
    setSendingFeedback(false);
    handleModalClose("feedbackForm");
  };

  return (
    <AppLayout fluid={true}>
      <ProfileHeader user={user} />
      <Container maxWidth={"lg"} className={"pb-5"}>
        <div className={"mt-5"}>
          <ProfileMenu user={user} />
          <Card
            width={"w-full"}
            padding={2}
            className={"mt-5"}
            rounded={"rounded-lg"}
          >
            <div>
              <div>
                <FormTitleSubtitle
                  title={"Medlemskab"}
                  fontWeight="font-semibold"
                />
                {error && (
                  <Alert severity={severity} className={"my-3"}>
                    {error}
                  </Alert>
                )}
                <Hint title={getHintPaymentPlanLabel()} />
              </div>
              {user.activeMembership.type !== "premium" && (
                <div className="mt-5 flex w-full justify-center gap-10">
                  <MembershipCard
                    title={"Basic"}
                    checkItems={[
                      "Se annonce overskrifter",
                      "Se service katalog",
                    ]}
                    subtitle="Basic functions included"
                    price={"0 kr."}
                    backgroundColor={colors.white}
                    fontColor={colors.black}
                    borderColor={colors.lightGrey}
                    priceColor={"text-primary-green"}
                    iconBackground={colors.lightBlue}
                    onButtonClick={createFreeMembership}
                  />
                  <MembershipCard
                    onButtonClick={() => openModal("chooseMembership")}
                  />
                </div>
              )}
              {user.activeMembership.type === "premium" && (
                <div className="mt-5">
                  {user.activeMembership.awaitingCancellation && (
                    <div>
                      Din Premium medlemskab er ophørt og du vil fortsætte på
                      Basis medlemskab den::
                    </div>
                  )}
                  {!user.activeMembership.awaitingCancellation && (
                    <div>Dit medlemskab fornyes automatisk den:</div>
                  )}
                  <strong>
                    {format(new Date(user.activeMembership.dateTo), "d.M.Y")}
                  </strong>
                  {!user.activeMembership.awaitingCancellation && (
                    <div className="mt-10 sm:w-1/6 w-full">
                      <OutlinedButton
                        color={"text-error"}
                        title={"Annullér medlemskab"}
                        onClick={() => openModal("cancelPlan")}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="sm:w-1/4 w-full pt-3"></div>
          </Card>
        </div>
      </Container>
      <Modal modalId={"chooseMembership"} title={"Vælg betalingsmetode"}>
        {!showInvoiceForm && (
          <div>
            <div className={"mb-4 sm:pr-20"}>
              Vælg betalingsmetode for dit Premium medlemskab
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller
                    defaultValue={false}
                    name="confirmation"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        style={{
                          color: colors.primary,
                        }}
                        {...field}
                      />
                    )}
                  />
                }
                label={<CheckboxLabel />}
              />
            </FormGroup>

            <div className={"flex gap-4"}>
              <div className="w-1/2">
                <Button
                  disabled={!confirmation}
                  textWidth="w-full"
                  defineHeight={false}
                  primary
                  onClick={makeWebPurchase}
                  loading={false}
                  title={"Betal med kreditkort"}
                >
                  Kreditkort
                </Button>
                <p className="text-sm text-grey-primary">
                  Du vil blive omdirigere til Quickpay
                </p>
              </div>
              <div className="w-1/2">
                <Button
                  disabled={!confirmation}
                  primary
                  textWidth="w-full"
                  onClick={chooseInvoicePayment}
                  title={"Betal med faktura"}
                  defineHeight={false}
                  loading={false}
                >
                  Faktura
                </Button>
              </div>
            </div>
          </div>
        )}
        {showInvoiceForm && (
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <p className="mb-3">
                Venligst udfyld kontaktperson for fakturering
              </p>
              <div className="grid grid-cols-2 gap-5">
                <InputField
                  label={"Kontaktperson"}
                  defaultValue={user?.name ?? ""}
                  name="contactPerson"
                  error={errors.contactPerson?.message}
                  onChange={async (e: any, data: any) => {
                    setValue(e.target.name, e.target.value);
                  }}
                />

                <InputField
                  label={"Contact Email"}
                  defaultValue={user?.email ?? ""}
                  name="contactEmail"
                  error={errors.contactEmail?.message}
                  onChange={async (e: any, data: any) => {
                    setValue(e.target.name, e.target.value);
                  }}
                />
              </div>
              <div className="flex justify-center">
                <Button
                  textWidth="w-1/2"
                  onClick={handleSubmit(onSubmit)}
                  loading={false}
                  title={"Confirm kontaktperson"}
                  primary={true}
                />
              </div>
            </form>
          </div>
        )}
      </Modal>
      <Modal modalId={"cancelPlan"} title={"Annullér medlemskab"}>
        <div className="mb-3">
          Efter annullering af din medlemskab vil du overgå til basis medlemskab
          fra den {format(new Date(user.activeMembership.dateTo), "d.M.Y")}.
        </div>
        <OutlinedButton
          color={"text-error"}
          title={"Annullér medlemskab"}
          onClick={cancelPlan}
        />
      </Modal>
      <Modal modalId={"feedbackForm"} title={"Din feedback"}>
        <div className="mb-3">Vi vil gerne høre hvis du deler årsagen</div>
        <div className="flex flex-col">
          {feedbackReasons.map((reason, index) => {
            return (
              <div
                className="border-b py-3 flex items-center cursor-pointer"
                key={index}
                onClick={() => setFeedback(reason)}
              >
                <div className="mr-3">
                  <img
                    src={reason === feedback ? Selected : Checked}
                    alt={"Unselected item"}
                  />
                </div>
                <div>{reason}</div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-center mt-5">
          <Button
            textWidth="w-1/2"
            onClick={sendFeedback}
            loading={sendingFeedback}
            title={"Send feedback"}
            primary={true}
          />
        </div>
      </Modal>
    </AppLayout>
  );
};
