import React from "react";
import { translations } from "../../utilities/variables";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputField } from "../atoms/InputField";
import { Button } from "../atoms/Button";
import Alert from "@material-ui/lab/Alert";
import { publicClient } from "../../utilities/Apollo";
import CHECK_CVR_NUMBER from "../../query/Auth/checkCvr";
import { ApolloError, FetchResult } from "@apollo/client";
import { ValidCvrCompany } from "../../types/Customer";

type Props = {
  afterFormSubmit: (data: ValidCvrCompany, cvr: string) => void;
};

interface IFormInput {
  cvr: string;
}

const schema = yup.object().shape({
  cvr: yup.string().required(translations.required),
});

export const CvrForm: React.ComponentType<Props> = ({ afterFormSubmit }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setLoading(true);
    trigger();
    await checkCvrNumber(data);
  };

  React.useEffect(() => {
    register("cvr", { required: true });
  }, [register]);

  const checkCvrNumber = async (data: any) => {
    setLoading(true);
    setError(null);
    await publicClient
      .mutate({
        mutation: CHECK_CVR_NUMBER,
        variables: {
          cvr: data.cvr,
        },
      })
      .then((response) => onCvrSuccess(response, data.cvr))
      .catch((error) => onCvrError(error));
  };

  const onCvrSuccess = async (response: FetchResult, cvr: string) => {
    let data = response?.data?.checkCvrNumber;
    afterFormSubmit(data, cvr);
  };

  const onCvrError = (error: ApolloError) => {
    setLoading(false);
    setError(error.graphQLErrors[0].message);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col"}>
      {error && (
        <Alert severity="error" className={"my-3"}>
          {error}
        </Alert>
      )}
      <InputField
        label={"CVR"}
        defaultValue=""
        name="cvr"
        error={errors.cvr?.message}
        onChange={async (e: any, data: any) => {
          setValue(e.target.name, e.target.value);
        }}
      />

      <Button
        onClick={handleSubmit(onSubmit)}
        loading={loading}
        title={"Fortsæt"}
        primary={true}
      />
    </form>
  );
};
