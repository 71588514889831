import React from "react";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer/Drawer";
import styled from "styled-components";
import { withTheme } from "@material-ui/styles";
import { MenuLink } from "./MenuLink";

const drawerWidth = 240;

type Props = {
  open: boolean;
  handleDrawerClose: () => void;
  window?: () => Window;
};

const StyledDrawerWrapper = withTheme(styled.nav`
  height: 100%;
  ${(props) => props.theme.breakpoints.up("sm")} {
    height: 100%;
    width: ${drawerWidth}px;
    flex-shrink: 0;
    background-color: white;
  }
`);

const StyledDrawer = withTheme(styled(Drawer)`
  .MuiDrawer-paper {
    position: relative;
    white-space: nowrap;
    border: none;
    width: ${drawerWidth}px;
    transition: ${(props) =>
      props.theme.transitions.create(["width"], {
        easing: props.theme.transitions.easing.sharp,
        duration: props.theme.transitions.duration.enteringScreen,
      })};
  }
`);

export const DrawerComponent: React.ComponentType<Props> = ({
  open,
  handleDrawerClose,
  window,
}) => {
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const renderDrawer = () => {
    return (
      <List>
        <MenuLink link={"/listings"} label={"Hjem"} />
        <MenuLink link={"/my-items"} label={"Mine annoncer"} />
        <MenuLink link={"/post-item"} label={"Ny annonce"} />
        <MenuLink link={"/messages"} label={"Beskeder"} />
      </List>
    );
  };

  return (
    <StyledDrawerWrapper className={"sm:hidden block"}>
      <div>
        <StyledDrawer
          container={container}
          variant={"temporary"}
          onClose={handleDrawerClose}
          open={open}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {renderDrawer()}
        </StyledDrawer>
      </div>
    </StyledDrawerWrapper>
  );
};
