import { gql } from "@apollo/client";

const GET_CURRENT_USER = gql`
  query currentCustomer {
    currentCustomer {
      id
      uuid
      email
      company
      address
      city
      zip
      name
      ownerId
      image
      phone
      paymentType
      invoiceContactPerson
      invoiceContactEmail
      activeMembership {
        id
        type
        isExpired
        dateTo
        awaitingCancellation
      }
    }
  }
`;

export default GET_CURRENT_USER;
