import React from "react";
import styled from "styled-components";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { colors } from "../../utilities/variables";
import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  styled as muiStyled,
} from "@mui/material";
import { ButtonProps } from "@material-ui/core";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import { Link } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
type Props = {};

const StyledMenu = styled((props) => <Menu {...props} />)`
  .MuiPaper-root {
    box-shadow: 0px 5px 10px rgba(28, 33, 52, 0.05) !important;
    min-width: 180px;
  }
`;

const ColorButton = muiStyled(Button)<ButtonProps>(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: colors.primary,
  "&:hover": {
    backgroundColor: colors.primary,
  },
}));

export const AppDownloadMenu: React.ComponentType<Props> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={"lg:flex hidden"}>
        <ColorButton
          variant="contained"
          disableElevation
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event) => handleClick(event)}
        >
          Download app
        </ColorButton>
      </div>
      <div className={"lg:hidden flex text-primary-green"}>
        <IconButton
          id="basic-button"
          aria-controls="basic-menu"
          color="inherit"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event) => handleClick(event)}
        >
          <DownloadIcon />
        </IconButton>
      </div>

      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuList>
          <MenuItem
            component={Link}
            to={{
              pathname: "https://apps.apple.com/us/app/engrosauto/id1614207540",
            }}
            target="_blank"
          >
            <ListItemIcon>
              <AppleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download appen til iOS</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            component={Link}
            to={{
              pathname:
                "https://play.google.com/store/apps/details?id=dk.engrosapp.auto",
            }}
            target="_blank"
          >
            <ListItemIcon>
              <AndroidIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText> Download appen til Android</ListItemText>
          </MenuItem>
        </MenuList>
      </StyledMenu>
    </>
  );
};
