import { gql } from "@apollo/client";

const SEARCH_POSTS = gql`
  query searchPosts(
    $brands: [Int]
    $categories: [Int]
    $regions: [String]
    $text: String
    $customer: Boolean
  ) {
    searchPosts(
      input: {
        brands: $brands
        categories: $categories
        text: $text
        region: $regions
        customer: $customer
      }
    ) {
      favorites
      posts {
        id
        uuid
        title
        price
        createdAt
        city
        zip
        askForBids
        sold
        images {
          url
        }
      }
    }
  }
`;

export default SEARCH_POSTS;
