import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import LoadingButton from "@mui/lab/LoadingButton";
type Props = {
  onClick: () => void;
  color?: string;
  title: string;
  fontColor?: string;
  width?: string;
  defineHeight?: boolean;
};

const Wrapper = styled((props) => <div {...props} />)``;

const StyledButton = styled((props) => (
  <LoadingButton color={"inherit"} {...props} />
))`
  border-radius: 2px !important;
`;

const ButtonTitle = styled.span`
  font-family: Poppins;
`;

export const OutlinedButton: React.ComponentType<Props> = ({
  onClick,
  title,
  color = "text-grey-primary",
  fontColor = color,
  width = "",
  defineHeight = true,
}) => {
  return (
    <Wrapper className={classNames("my-4", color, width)}>
      <StyledButton
        onClick={onClick}
        variant={"outlined"}
        className={classNames("w-full", { "h-12": defineHeight })}
      >
        <ButtonTitle
          className={classNames("normal-case font-medium text-base", fontColor)}
        >
          {title}
        </ButtonTitle>
      </StyledButton>
    </Wrapper>
  );
};
