import React from "react";
import styled from "styled-components";
import classNames from "classnames";

type Props = {
  padding?: number;
  width?: string;
  className?: string;
  rounded?: string;
};

const StyledCard = styled((props) => <div {...props} />)`
  padding: ${(props) => (props.$padding != null ? props.$padding : 3)}rem;
`;

export const Card: React.ComponentType<Props> = ({
  children,
  padding,
  className,
  width = "w-4/5",
  rounded = "rounded-xl",
}) => {
  return (
    <StyledCard
      className={classNames("bg-white", width, className, rounded)}
      $padding={padding}
    >
      {children}
    </StyledCard>
  );
};
