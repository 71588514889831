import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ChipComponent } from "../../component/atoms/Chip";
import { colors } from "../../utilities/variables";
import { Post } from "../../types/Post";
import DefaultImage from "../../assets/default-engros.png";
import { FavoriteIcon } from "../atoms/FavoriteIcon";
import { client } from "../../utilities/Apollo";
import SET_POST_FAVORITE from "../../query/Post/setPostFavorite";
import { ApolloError, FetchResult } from "@apollo/client";
import { useSelector } from "../../store/hooks";

const CardImage = styled((props) => <div {...props} />)`
  background: url("${(props) => props.background}");
  height: 200px;
  background-position: center;
`;

export interface Props {
  post: Post;
  favorites: [number] | [];
  afterRating: (type: boolean, id: number) => void;
  isFavorite?: boolean;
}

export const PostItemCard: React.FC<Props> = ({
  post,
  favorites,
  afterRating,
  isFavorite = false,
}) => {
  const [loading, setLoading] = React.useState(false);
  const { user } = useSelector((state) => state.user);

  const handlePostFavorite = async (event?: Event) => {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);
    await client
      .mutate({
        mutation: SET_POST_FAVORITE,
        variables: {
          uuid: post.uuid,
        },
      })
      .then((response) => onSuccess(response))
      .catch((error) => onError(error));
  };

  const onSuccess = (response: FetchResult) => {
    setLoading(false);
    afterRating(response?.data?.handleFavorite, post.id);
  };

  const onError = (error: ApolloError) => {
    setLoading(false);
  };

  return (
    <article className="overflow-hidden rounded-lg bg-white">
      <Link to={"/listings/" + post.uuid}>
        <div className={"relative"}>
          <CardImage
            background={
              post.images?.length > 0 ? post.images[0].url : DefaultImage
            }
          />
          {user.id !== 0 && (
            <div className={"absolute top-2 right-2 z-10"}>
              <FavoriteIcon
                handlePostFavorite={handlePostFavorite}
                isFavorite={favorites.includes(post.id as never) || isFavorite}
                loading={loading}
              />
            </div>
          )}
          {post.askForBids && !post.sold && (
            <div className={"absolute bottom-4 left-2"}>
              <ChipComponent
                backgroundColor={colors.blue}
                textColor={colors.white}
                title={"Byd"}
              />
            </div>
          )}
          {post.sold && (
            <div className={"absolute bottom-4 left-2"}>
              <ChipComponent
                backgroundColor={colors.primary}
                textColor={colors.white}
                title={"Solgt"}
              />
            </div>
          )}
        </div>
      </Link>

      <header className="flex items-center justify-between leading-tight px-2 md:px-4 my-2 pt-3">
        <p className="text-grey-darker text-sm">
          {post.price !== null &&
            post.price !== 0 &&
            post.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
              " kr."}
        </p>
        <p className={"text-sm text-grey-primary"}>
          {/* {formatDistance(new Date(post.createdAt), new Date(), {
            addSuffix: true,
            locale: da,
          })} */}
        </p>
      </header>
      <h1 className="text-lg px-2 md:px-4">
        <Link
          className="no-underline text-black font-semibold text-lg line-clamp-1 hover:text-grey-hover transition-colors duration-150"
          to={"/listings/" + post.uuid}
        >
          {post.title}
        </Link>
      </h1>
      <footer className="flex items-center justify-between leading-none px-2 md:px-4 my-2 pb-3">
        <p className="text-grey-primary text-sm">
          {post.zip} {post.city}
        </p>
      </footer>
    </article>
  );
};
