import { gql } from "@apollo/client";

const MAKE_INVOICE_PURCHASE = gql`
  mutation makeInvoicePurchase(
    $contactPerson: String!
    $contactEmail: String!
  ) {
    makeInvoicePurchase: makeInvoicePurchase(
      input: { contactPerson: $contactPerson, contactEmail: $contactEmail }
    )
  }
`;

export default MAKE_INVOICE_PURCHASE;
