import { gql } from "@apollo/client";

const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $company: String!
    $email: String!
    $address: String!
    $city: String!
    $phone: String!
    $name: String!
    $zip: String!
  ) {
    updateProfile: updateProfile(
      input: {
        company: $company
        address: $address
        name: $name
        city: $city
        email: $email
        phone: $phone
        zip: $zip
      }
    ) {
      id
      uuid
      company
      name
      address
      zip
      city
      email
      active
      image
      phone
      role
      ownerId
      invoiceContactPerson
      paymentType
      invoiceContactEmail
      activeMembership {
        id
        type
        isExpired
        dateTo
        awaitingCancellation
      }
    }
  }
`;

export default UPDATE_PROFILE;
