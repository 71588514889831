import React from "react";
import { NavBar } from "../../component/atoms/NavBar";
import Container from "@mui/material/Container";
import styled from "styled-components";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { DrawerComponent } from "../molecules/Drawer";
import { io } from "socket.io-client";
import { useSelector } from "../../store/hooks";
import { Footer } from "../atoms/Footer";

const socket = io(process.env.REACT_APP_SOCKET_URL || "", {
  reconnectionDelayMax: 10000,
});

const WrapperComponent = styled(Box)`
  background-color: #f3f7fc;
  height: 100%;
`;

type Props = {
  fluid?: boolean;
};

const StyledContainer = styled(Container)`
  padding-left: ${(props) => (!props.maxWidth ? 0 : 24)}px !important;
  padding-right: ${(props) => (!props.maxWidth ? 0 : 24)}px !important;
  @media screen and (max-width: 600px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;
export const AppLayout: React.ComponentType<Props> = ({
  children,
  fluid = false,
}) => {
  const { user } = useSelector((state) => state.user);

  socket.emit("subscribe", { room: user.id });
  const [open, setOpen] = React.useState(false);
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <WrapperComponent className="sm:flex block">
      <CssBaseline />
      <NavBar
        position={"fixed"}
        showBorder={true}
        handleDrawerOpen={handleDrawerOpen}
      />
      <DrawerComponent open={open} handleDrawerClose={handleDrawerClose} />
      <StyledContainer maxWidth={fluid ? false : "lg"}>
        <Box component="main" sx={{ flexGrow: 1, p: fluid ? 0 : 3 }}>
          <Toolbar />

          {children}
        </Box>
        <Footer />
      </StyledContainer>
    </WrapperComponent>
  );
};
