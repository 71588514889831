import React from "react";
import { Modal } from "./Modal";
import { Button } from "../atoms/Button";
import { OutlinedButton } from "../atoms/OutlinedButton";
import { useDispatch } from "../../store/hooks";
import { modalSlice } from "../../store/slices/modal";
import { backdropSlice } from "../../store/slices/backdrop";
import { snackbarSlice } from "../../store/slices/snackbarSlice";
import { colors } from "../../utilities/variables";
import { client } from "../../utilities/Apollo";
import { ApolloError, FetchResult } from "@apollo/client";
import POST_DELETE from "../../query/Post/deletePost";

type Props = {
  deletePostItem: string;
  callback: () => void;
};

export const DeleteListing: React.ComponentType<Props> = ({
  deletePostItem,
  callback,
}) => {
  const dispatch = useDispatch();

  const handleBackdrop = (state: boolean) => {
    dispatch(
      backdropSlice.actions.put({
        open: state,
      })
    );
  };

  const handleSnackbar = (message: string, severity: string) => {
    dispatch(
      snackbarSlice.actions.put({
        open: true,
        message: message,
        severity: severity,
      })
    );
  };

  const deletePost = async () => {
    handleBackdrop(true);
    await client
      .mutate({
        mutation: POST_DELETE,
        variables: {
          uuid: deletePostItem,
        },
      })
      .then((response) => onDeleteSuccess(response))
      .catch((error) => onActionError(error));
  };

  const onDeleteSuccess = async (response: FetchResult) => {
    handleBackdrop(false);
    handleSnackbar("Annonce er slettet", "success");
    handleModalClose();
    callback();
  };

  const onActionError = (error: ApolloError) => {
    handleBackdrop(false);
  };

  const handleModalClose = () => {
    dispatch(
      modalSlice.actions.update({
        deleteListing: false,
      })
    );
  };

  return (
    <Modal modalId={"deleteListing"} title={"Slet annonce"}>
      <div className={"mb-4 sm:pr-20"}>
        Er du sikker på at du vil slet denne annonce?
      </div>
      <div className={"flex gap-4"}>
        <Button
          defineHeight={false}
          onClick={deletePost}
          loading={false}
          title={"Yes, delete"}
          backgroundColor={colors.error}
        >
          Ja, slet
        </Button>
        <OutlinedButton
          onClick={handleModalClose}
          title={"Cancel"}
          defineHeight={false}
        >
          Annuller
        </OutlinedButton>
      </div>
    </Modal>
  );
};
