import { configureStore } from "@reduxjs/toolkit";
import { snackbarSlice } from "./slices/snackbarSlice";
import { backdropSlice } from "./slices/backdrop";
import { userSlice } from "./slices/userSlice";
import { modalSlice } from "./slices/modal";

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    snackbar: snackbarSlice.reducer,
    backdrop: backdropSlice.reducer,
    modal: modalSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
