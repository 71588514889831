import { gql } from "@apollo/client";

const UPDATE_POST_STATUS = gql`
  mutation customerUpdatePostStatus(
    $id: Int!
    $sold: Boolean
    $active: Boolean
  ) {
    customerUpdatePostStatus(input: { id: $id, active: $active, sold: $sold }) {
      id
      createdAt
    }
  }
`;

export default UPDATE_POST_STATUS;
