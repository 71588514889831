import { createSlice } from "@reduxjs/toolkit";

import { objectReducerGenerator } from "../objectReducerGenerator";

export interface BackdropState {
  open: boolean;
}

const initialState: BackdropState = {
  open: false,
};

export const backdropSlice = createSlice({
  name: "backdrop",
  initialState,
  reducers: {
    ...objectReducerGenerator<BackdropState>(),
  },
});
