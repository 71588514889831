import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import { format } from "date-fns";
import { Message } from "../../types/Message";
import { Customer } from "../../types/Customer";
import { colors } from "../../utilities/variables";

type Props = {
  message: Message;
  user: Customer;
};

const StyledAnswerWrapper = styled.li`
  margin-bottom: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledAnswerBackground = styled.span`
  margin-bottom: 0.2rem;
  position: relative;
  color: #363636;
  padding: 0.5rem 1rem;
  border-radius: 22px;
  background-color: #ffffff;
  max-width: 30rem;
  font-size: 0.9rem;
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-word;
  &.me {
    margin-left: 2rem;
    background-color: ${colors.black};
    align-self: flex-end;
    color: white;
  }
  &.you {
    margin-right: 2rem;
    background-color: ${colors.inputField};
  }
`;

const ChatTime = styled.div`
  font-size: 0.8rem;
  text-align: right;
  color: ${colors.grey};
  &.me {
    padding-right: 1rem;
    align-self: flex-end;
  }
  &.you {
    padding-left: 1rem;
  }
`;

export const Answer: React.ComponentType<Props> = ({ message, user }) => {
  return (
    <StyledAnswerWrapper>
      <StyledAnswerBackground
        className={classNames({
          me: message?.owner.id === user.id,
          you: message?.owner.id !== user.id,
        })}
      >
        <div>{message.message}</div>
      </StyledAnswerBackground>
      <ChatTime
        className={classNames({
          me: message?.owner.id === user.id,
          you: message?.owner.id !== user.id,
        })}
      >
        {message.owner.name}, {format(new Date(message.createdAt), "d/M HH:mm")}
      </ChatTime>
    </StyledAnswerWrapper>
  );
};
