import { gql } from "@apollo/client";

const GET_MY_POSTS = gql`
  query getMyPosts($active: Boolean, $sold: Boolean) {
    getMyPosts(input: { active: $active, sold: $sold }) {
      id
      uuid
      title
      sold
      price
      createdAt
      images {
        url
      }
    }
  }
`;

export default GET_MY_POSTS;
