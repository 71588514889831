import React from "react";
import { useDispatch } from "../../store/hooks";
import { modalSlice } from "../../store/slices/modal";
import { Modal } from "../molecules/Modal";

type Props = {};

export const Footer: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const openModal = React.useCallback(
    () => {
      dispatch(
        modalSlice.actions.update({
          aboutUs: true,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <>
      <div className="text-center my-5">
        <div className="text-sm">
          EngrosAppen - Randersvej 65, 8870 Langå- CVR-nr: 42826863
        </div>
        <div className="mt-1 text-sm flex justify-center flex-wrap">
          <div className="text-primary-green cursor-pointer">
            <span onClick={openModal}>Om os</span>
          </div>
          <span className="mx-5">|</span>

          <div className="text-primary-green">
            <a href="/Handlebetingelser.pdf" target="_blank">
              Handlebetingelser
            </a>
          </div>
          <span className="mx-5">|</span>
          <div className="text-primary-green">
            <a href="/privacy_policy.pdf" target="_blank">
              Privatslivpolitik
            </a>
          </div>
          <span className="mx-5">|</span>
          <div className="text-primary-green">
            <a href="mailto:info@engrosappen.dk">info@engrosappen.dk</a>
          </div>
        </div>
      </div>
      <Modal modalId="aboutUs" title="Om os">
        <div>
          <p>Engrosappen er opstået på et lille kontor ved Randers.</p>
          <p className="mb-5">
            Annette Abild som ejer Abild Trading står bag ideen.{" "}
          </p>
          <p className="mb-5">
            Ideen er opstået da hun flere gange har siddet på markedspladser og
            De gule sider for at finde brugte vinterhjul, ekstra sæder til biler
            som skal bygges om. Det at handle med private er krævende, især hvis
            man sælger noget. Spørgsmålene vil ingen ende tage, og man har brug
            for seriøs handel i en travl hverdag. Hun har ofte også manglet et
            organiseret netværk. Hvor man kan få ryddet op i de gemmer man har
            ude i skuret.
          </p>
          <p>
            Engrosappen giver mulighed for at samle en "pakke med ting" eller
            mulighed for at søge en vare eller tjeneste man kunne have brug for.
            Engrosappen er det seriøse netværk for autobranchen.
          </p>
        </div>
      </Modal>
    </>
  );
};
