import React from "react";
import styled from "styled-components";
import { colors } from "../../utilities/variables";

type Props = {};

const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.lightGrey};
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;
export const Divider: React.ComponentType<Props> = () => {
  return <StyledDivider />;
};
