import React from "react";
import styled from "styled-components";
import LoadingButton from "@mui/lab/LoadingButton";
import classNames from "classnames";
import { colors } from "../../utilities/variables";

type Props = {
  onClick: (event?: any) => void;
  loading: boolean;
  title: string;
  primary?: boolean;
  backgroundColor?: string;
  defineHeight?: boolean;
  type?: string;
  useMargin?: boolean;
  fontSize?: string;
  radius?: number;
  width?: number;
  textWidth?: string;
  disabled?: boolean;
};

const Wrapper = styled((props) => <div {...props} />)``;

const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)`
  ${({ $backgroundColor, $disabled }) =>
    $backgroundColor &&
    !$disabled &&
    `
    background-color: ${$backgroundColor} !important;
  `}
  ${({ $disabled }) =>
    $disabled &&
    `
    background-color: rgba(0, 0, 0, 0.26) !important;
  `}
  ${({ $primary }) =>
    !$primary &&
    `
    width: 150px;
  `}
  ${({ $radius }) => `
  border-radius: ${$radius}px !important;
  `}
  ${({ $width }) =>
    $width &&
    `
    width: ${$width}px;
  `}
`;

const ButtonTitle = styled.span`
  font-family: Poppins;
`;

export const Button: React.ComponentType<Props> = ({
  onClick,
  loading,
  title,
  primary,
  backgroundColor = colors.primary,
  defineHeight = true,
  type = "button",
  useMargin = true,
  fontSize = "text-base",
  radius = 2,
  width,
  textWidth,
  disabled = false,
}) => {
  const handleOnClick = () => {
    if (!disabled) {
      onClick();
    }
  };
  return (
    <Wrapper className={classNames({ "my-4": useMargin }, textWidth)}>
      <StyledLoadingButton
        $radius={radius}
        $width={width}
        $disabled={disabled}
        $backgroundColor={backgroundColor}
        $primary={primary}
        type={type}
        loading={loading}
        onClick={handleOnClick}
        className={classNames("flex focus:outline-none justify-center", {
          "bg-gradient-to-r from-primary-green to-primary-gradient":
            primary && !disabled,
          "w-full": primary,
          "h-12": defineHeight,
        })}
      >
        {!loading && (
          <ButtonTitle
            className={classNames(
              "text-white normal-case font-medium",
              fontSize
            )}
          >
            {title}
          </ButtonTitle>
        )}
      </StyledLoadingButton>
    </Wrapper>
  );
};
